import React, { useEffect, useState } from "react"
import useCart from "hooks/useCart"
import { useLocation, useNavigate } from "react-router-dom"
import { usePathsNavigator } from "util/paths"
import NavigationItem, { ICONS } from "./NavigationItem"
import Modal from "components/displays/Modal"
import { Modals } from "styles/ModalStyle"
import LogOut from "pages/login/Logout"
import { Container, FooterContainer } from "./styles/NavigationBarStyles"
import { useRecoilValue } from "recoil"
import { selectedSectionIdAtom } from "state"

enum routes {
    section = "section",
    orders = "orders",
    shoppingCart = "shoppingCart",
    logout = "logout",
    products = "products",
}

const checkCurrentRoute = (route: string): routes => {
    const value = Object.entries(routes).find(curRoute => {
        return route.includes(curRoute[1])
    })

    return value![1]

}

const CartIconWithProducts = (props: {
    isActive: boolean,
    handleNav: () => void,
}) => {
    const { cartLenght, } = useCart()
    const { isActive, handleNav, } = props

    return <NavigationItem
        icon={ICONS.BAG}
        label="Carrito"
        isActive={isActive}
        badge={cartLenght}
        onClick={handleNav}
        disable={false}
    />

}
const NavigationBar = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const paths = usePathsNavigator()
    const sectionId = useRecoilValue(selectedSectionIdAtom)
    const [currentRoute, setCurrentRoute,] = useState<routes>(routes.products)
    const [logoutModal, setLogoutModal,] = useState<boolean>(false)

    useEffect(() => {
        if (logoutModal) {
            setCurrentRoute(routes.logout)
        } else {
            const route = checkCurrentRoute(location.pathname)
            if (route) {
                setCurrentRoute(route)
            }
        }
    }, [logoutModal,])

    const handleNav = (site: string) => () => navigate(site)

    return (
        <FooterContainer>
            <Container>
                <NavigationItem
                    icon={ICONS.SECTION}
                    label="Productos"
                    isActive={currentRoute === routes.products}
                    onClick={handleNav(paths(routes.products))}
                    disable={!sectionId}
                />
                {sectionId
                    ? <CartIconWithProducts
                        handleNav={handleNav(paths(routes.shoppingCart))}
                        isActive={currentRoute === routes.shoppingCart}
                    />
                    : <NavigationItem
                        icon={ICONS.BAG}
                        label="Carrito"
                        isActive={currentRoute === routes.shoppingCart}
                        badge={undefined}
                        onClick={handleNav(paths(routes.shoppingCart))}
                        disable={true}
                    />
                }
                <NavigationItem
                    icon={ICONS.ORDER}
                    label="Pedidos"
                    isActive={currentRoute === routes.orders}
                    onClick={handleNav(paths(routes.orders))}
                    disable={false}
                />
                <NavigationItem
                    icon={ICONS.USER}
                    label="Perfil"
                    isActive={currentRoute === routes.logout}
                    onClick={() => setLogoutModal(true)}
                    disable={false}
                />
            </Container>
            {logoutModal && <Modal
                modalIsOpen={logoutModal}
                modalStyle={Modals.confirm}
                closeModal={() => setLogoutModal(false)}
            >
                <LogOut
                    cancel={() => setLogoutModal(false)}
                    logoutRedirect={paths("root")} />
            </Modal>}
        </FooterContainer>
    )
}

export default NavigationBar