import styled, { css } from "styled-components"
export const MainDiv = styled.div`
    padding:8px;
    font-family: Silka, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    z-index: 1;
    position: relative;
    flex: 1 1 auto;
    overflow-y: auto;
    width: 100%;
`

type ContainerDivPropsType = {
    selected: boolean,
}
export const ContainerDiv = styled.div<ContainerDivPropsType>`
    display: flex;
    height: 90px;
    align-items: center;
    margin-bottom: 8px;
    border-radius: 24PX;
    background-color: ${({ theme, }) => theme.palette.common.white};
    border: 1px solid ${({ theme, }) => theme.palette.common.grey10};
    padding:21px 17px 24px 20px;

    ${props => props.selected && css`
        border: 1px solid ${({ theme, }) => theme.palette.primary.main};
        background-color: ${({ theme, }) => theme.palette.primary.main};
        font-weight: 600;
        color: ${({ theme, }) => theme.palette.common.black};

        img{
            filter:invert();
        }

        ${StyledP} {
            color: ${({ theme, }) => theme.palette.common.white};
        }
    `}
`

export const SectionDiv = styled.div`
    display: block;
    display: flex;
    flex-direction: column;
    flex: 6;
`

export const ImageWrapper = styled.div`
    width: 40px;
    height: 40px;
    margin-right:29px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const SectionDescDiv = styled.div`
    display: flex;
    align-items: start;
    max-width: 100%;
`

export const StyledImg = styled.img`
    border-radius: 8px;
    width: 40px;
    height: 40px;
    padding: 6px;
`

export const StyledP = styled.p`
    color:  ${({ theme, }) => theme.palette.common.black};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 18px;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.02em;
`
export const SelectionMarkWrapper = styled.div`
    display:flex;
    justify-content: flex-end;
`

type SelectionMarkPropsType = {
    selected?: boolean,
}
export const SelectionMark = styled.div < SelectionMarkPropsType>`
    width:29px;
    height:29px;
    display: flex;
    border-radius:29px;
    margin:5px;
    background-color:${({ theme, }) => theme.palette.common.white};
    border: ${({ selected, theme, }) => (selected ? "none" : `3px solid ${theme.palette.common.grey8}`)};
    justify-self: flex-end;
    justify-content: center;
    align-items: center;

`