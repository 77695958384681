import styled from "styled-components"
import { motion } from "framer-motion"
import { OrderState } from "gdu-vq-common/src/api/interfaces/shared/models/Order"

export const Wrapper = styled(motion.div) <{ state: OrderState, }>`
  padding: 6px 12px;
  width: 100%;
  display: flex ;
  align-items: center;
  color: ${({ theme, }) => theme.palette.common.white};
  text-transform: uppercase;
  font-weight: bold;
  justify-content: space-between;
  z-index: 3;
  position: sticky;
  top: 85px;
  
  background-color: ${({ theme, state, }) => {
    switch (state) {
      case OrderState.completed:
        return theme.palette.primary.main
      case OrderState.inProgress:
        return theme.palette.common.black2
      case OrderState.onHold:
        return theme.palette.common.black
      default:
        return theme.palette.common.black2
    }
  }};

  span{
      color: ${({ theme, }) => theme.palette.primary.main};
    }
`

export const LabelWrapper = styled.div`
  display: flex;
  gap: 8px;
  font-size: 14px;
`

export const Count = styled.span`
  background-color: ${({ theme, }) => theme.palette.common.white};
  color: ${({ theme, }) => theme.palette.old.primary.main};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 11px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`