import styled from "styled-components"

const Section = styled.div`
    margin-top: 16px;
`

const IntroText = styled.p`
    max-height: 100%;
    max-width: 100%;
    font-size: 14px;
    padding-bottom: 16px;
`

const IntroductionSteps = styled.div`
    background-color: ${({ theme, }) => theme.palette.common.grey};
	display: flex;
    flex-direction: row;
    padding: 16px;
    align-items: center;
    margin: 8px 0px;
    border-radius: ${({ theme, }) => theme.borderRadius};
    height: 77px;
`

const IconDiv = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${({ theme, }) => theme.palette.old.primary.main};
    height: 40px;
    width: 40px;
    border-radius: 50%;
    padding: 16px;
    align-items:center;
    justify-content: center;
`

const IconImg = styled.img`
    max-height: 24px;
    max-width: 24px;
`

const StepContent = styled.p`
    max-height: 100%;
    max-width: 80%;
    border-radius: ${({ theme, }) => theme.borderRadius};
    font-size: 14px;
    padding-left: 16px;
`

const BlodSpan = styled.span`
    font-weight: 600;
`

const ButtonWrapper = styled.div`
    position: fixed; 
    right: 16px;
    bottom: 16px;
`

const ProcessDiv = styled.div`
    width: 100%;
`

const ProcessTextDiv = styled.div`
    display: flex;
    justify-content: left;
    margin: 4px 0px;
    padding: 12px;
    background-color: ${({ theme, }) => theme.palette.old.primary.main};
    border-radius: ${({ theme, }) => theme.borderRadius};
`

const ProcessText = styled.p`
    font-weight: 500;
    font-size: 11px;
    line-height: 130%;
    letter-spacing: -0.3px;
    color: ${({ theme, }) => theme.palette.common.offWhite};
    text-transform: uppercase;
`

const StepsContainer = styled.div`
    padding-bottom: 80px;
`

const StepDiv = styled.div`
    background-color: ${({ theme, }) => theme.palette.common.grey};
	display: flex;
    flex-direction: row;
    padding: 16px;
    align-items: center;
    border-radius: 20px;
    margin-bottom: 8px;
`

const StepDivIcon = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${({ theme, }) => theme.palette.old.primary.main};
    height: 45px;
    width: 45px;
    border-radius: 50%;
    padding:16px;
    align-items:center;
    justify-content: center;
`

const StepIcon = styled.img`
    max-height: 24px;
    max-width: 24px;
`

const StepDesc = styled.p`
    max-height: 100%;
    max-width: 80%;
    font-size: 14px;
    padding-left: 16px;
    line-height: 140%;
    letter-spacing: -0.3px;
`

const ArrowImg = styled.img`
    margin-right: 8px;
    max-width:10px;
    max-height:10px;
    align-self: center;
`

export const WelcomeStyle = {
    Section,
    IntroText,
    IntroductionSteps,
    IconDiv,
    IconImg,
    StepContent,
    BlodSpan,
    ButtonWrapper,
    ProcessDiv,
    ProcessTextDiv,
    ProcessText,
    StepsContainer,
    StepDiv,
    StepDivIcon,
    StepIcon,
    StepDesc,
    ArrowImg,
}