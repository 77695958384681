import { Modals } from "styles/ModalStyle"
import React, { useState } from "react"
import Modal from "components/displays/Modal"
import ProductDetail from "pages/products/productList/ProductDetail"
import { useNavigate } from "react-router-dom"
import { usePathsNavigator } from "util/paths"

const ProductDetailModal = () => {
  const [modalIsOpen, setModalIsOpen,] = useState(true)
  const navigate = useNavigate()
  const paths = usePathsNavigator()
  const closeModal = () => {
    setModalIsOpen(false)
  }
  const onAfterModalClose = () => {
    navigate(paths("shoppingCart"))
  }
  return <Modal
    modalStyle={Modals.screen}
    modalIsOpen={modalIsOpen}
    closeModal={closeModal}
    onAfterModalClose={onAfterModalClose}>
    <ProductDetail
      backPath="shoppingCart"
    />
  </Modal>
}

export default ProductDetailModal