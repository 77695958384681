/* eslint-disable no-confusing-arrow */
import styled from "styled-components"
import { NotificationType } from "../InlineNotification"

export const Wrapper = styled.div<{ notificationType: NotificationType, }>`
  display: flex;
  flex-direction: row;
  padding: 8px 6px;
  width: 100%;
  min-height: auto;
  background: ${({ theme, notificationType, }) =>
    notificationType === NotificationType.error
      ? theme.palette.old.info.contrastText
      : theme.palette.old.info.contrastText
  };
  border-radius: 8px;
  font-size: 11px;
  align-items: center;
  color: ${({ theme, notificationType, }) =>
    notificationType === NotificationType.error
      ? theme.palette.old.info.main
      : theme.palette.old.info.main
  };
`

export const ImgWrapper = styled.span`
  margin-right: 8px;
`