import { atom, selector, } from "recoil"
import {
    deleteCart,
    deleteSectionSelectId,
    getCart,
    getSectionId,
    getUserInfo,
    setCart,
    setSectionId,
} from "services/session"
import { Brands, Cart, ProductsSelector, StoreInfo, } from "types"
import { StoreBrands } from "api/ApiClient"
import { toDictionary } from "gdu-vq-common/src/helpers/toDictionary"
import { ConsumerOrder } from "gdu-vq-common/src/api/interfaces/consumer/models/ConsumerOrder"
import { StoreSection } from "gdu-vq-common/src/api/interfaces/consumer/models/ConsumerStore"
import { getStoreBrand } from "util/paths"

const clearSectionCart = () => {
    deleteSectionSelectId()
    deleteCart()
}

export const isLoggedAtom = atom<boolean>({
    key: "isLogged",
    default: (
        () => {
            const storageContent = getUserInfo()
            if (storageContent) {
                return !!storageContent.token
            }
            return false
        })(),
    effects: [
        ({ onSet, }) => {
            onSet((newValue) => {
                if (!newValue) {
                    clearSectionCart()
                }
            })

            return () => clearSectionCart()
        },
    ],
})

export const refreshStorePending = atom<boolean>({
    key: "refreshStore",
    default: false,
})

const getStore = async () => {

    const { brand, storeSlug, } = getStoreBrand()!

    if (brand && storeSlug) {
        const r = await StoreBrands.getStore("GET", {
            brand: Brands[brand as Brands],
            storeSlug: storeSlug!,
        })
        if (r.ok && !r.content) {
            return undefined
        }
        if (r.ok) {
            return {
                id: r.content!.id,
                description: r.content!.name,
                location: r.content!.location,
                sections: r.content!.secciones,
            }
        } else {
            throw new Error("Invalid response")
        }
    } else {
        throw new Error("No seccion Store")
    }
}

export const storeSelector = selector<StoreInfo | undefined>({
    key: "store",
    get: async () => await getStore(),
})

export const assertStoreSelector = selector<StoreInfo>({
    key: "assertStore",
    get: async ({ get, }) => {
        const store = await get(storeSelector)
        if (!store) {
            throw new Error("invalid store")
        }

        return store
    },
})

export const selectedSectionIdAtom = atom<string | null>({
    key: "selectedSectionIdAtom",
    default: getSectionId(),
    effects: [
        ({ onSet, }) => {
            onSet((newValue) => {
                if (newValue) {
                    setSectionId(newValue)
                    setCart({
                        sectionId: getSectionId()!,
                        cartItems: [],
                    })
                } else {
                    clearSectionCart()
                }
            })

            return () => clearSectionCart()
        },
    ],
})

export const sectionSelectAtom = selector<StoreSection | null>({
    key: "sectionSelectAtom",
    get: ({ get, }) => {
        const sectionId = get(selectedSectionIdAtom)
        if (sectionId) {
            const selector = get(assertStoreSelector)
            const section = selector.sections.find(s => s.id === sectionId)!
            if (section) {
                return section
            } else {
                clearSectionCart()
                throw new Error("Invalid section")
            }
        } else {
            return null
        }
    },
})

export const productFilterAtom = atom<string>({
    key: "productFilterAtom",
    default: "",
})

export const productsSelector = selector<ProductsSelector>({
    key: "products",
    get: async ({ get, }) => {

        const sectionId = get(sectionSelectAtom)?.id

        if (sectionId) {
            const r = await StoreBrands.getProducts("GET", {
                sectionId: sectionId,
            })

            if (r.ok) {
                return {
                    products: r.content,
                    productsById: toDictionary(r.content, (p) => p.id),
                }
            } else {
                throw new Error("Invalid response")
            }
        } else {
            throw new Error("No seccion selected")
        }
    },
})

export const ordersAtom = atom<ConsumerOrder[] | undefined>({
    key: "orders",
    default: undefined,
})

const cartCheck = () => {
    const cart = getCart()
    const sectionId = getSectionId()
    console.log("cart check", sectionId)
    if (cart) {
        if (sectionId === cart.sectionId) {
            return cart
        } else {
            deleteCart()
        }
    }
    if (sectionId) {
	console.log("no cart", sectionId)
        const emptyCart = {
            sectionId: sectionId!,
            cartItems: [],
        }
        return emptyCart
    }
    return null
}

export const cartAtom = atom<Cart | null>({
    key: "consumerCart",
    default: cartCheck(),
    effects: [
        ({ onSet, }) => {
		onSet((newValue) => {
                if (newValue) {
			const newCart = {
                        sectionId: getSectionId(),
                        cartItems: newValue.cartItems,
                    }
                    setCart(newCart)
                } else {
                    deleteCart()
                }
            })

            return () => deleteCart()
        },
    ],
})
