import React, { useState } from "react"
import Button from "./Button"
import { InputTextStyled } from "./styles/InputTextStyled"
import { useToaster } from "components/providers/ToastProvider"
import styled from "styled-components"

const TextWrapper = styled.div`
    font-family: 'Silka';
    font-style: normal;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.02em;
    color: rgba(0, 0, 0, 0.58);
    padding: 0 16px;
`

const SubTitle = styled(TextWrapper)`
margin-bottom: 8px;
`

const Title = styled(TextWrapper)`
    font-weight: 600;
`


const Feedback = (props: {
    sendComment: (comment: string) => void,
}) => {
    const { sendComment, } = props
    const toaster = useToaster()
    const [comment, setComment,] = useState<string>("")

    const handleSendComment = () => {
        sendComment(comment)
        setComment("")
        toaster.toast({
            title: "Success",
            content: "Mensaje enviado, muchas gracias por tu comentario!",
        })
    }

    return <div>
        <Title>¿Hay algo que podamos mejorar?</Title>
        <SubTitle>Tu opinión es importante para nosotros.</SubTitle>
        <InputTextStyled
            value={comment}
            placeholder="Dejanos tus comentarios."
            onChange={e => setComment(e.currentTarget.value)}
        />
        <Button
            disabled={!comment}
            primary={true}
            label="Enviar comentario"
            type="submit"
            onClick={handleSendComment}
        />
    </div>
}

export default Feedback