import * as React from "react"
import { createGlobalStyle } from "styled-components"
import tw, { theme, GlobalStyles as BaseStyles } from "twin.macro"

export const MODALS_ZINDEX = 5

const CustomStyles = createGlobalStyle`
  body {
    -webkit-tap-highlight-color: ${theme<string>`colors.purple.500`};
    ${tw`antialiased`}
  }
`

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
)

export default GlobalStyles