import React, { useState, } from "react"
import Layout from "components/layouts/Layout"
import ArrowButton from "components/utils/ArrowButton"
import useSession from "hooks/useSession"
import { useNavigate } from "react-router-dom"
import { PageTitle, } from "components/displays/Elements"
import { loyaltySteps, processItems } from "util/Steps"
import arrow_right from "assets/icons/arrow_right.svg"
import arrow_down from "assets/icons/arrow_down.svg"
import { WelcomeStyle } from "./style/WelcomeStyle"
import { usePathsNavigator } from "util/paths"
import useScroll from "hooks/useScroll"

const {
    Section,
    IntroText,
    IntroductionSteps,
    StepContent,
    BlodSpan,
    ButtonWrapper,
    ProcessDiv,
    ProcessTextDiv,
    ProcessText,
    StepsContainer,
    StepDiv,
    StepDivIcon,
    StepIcon,
    StepDesc,
    ArrowImg,
} = WelcomeStyle


const Welcome = () => {
    const { scrollToElement, elementRef, } = useScroll()
    const { isLogged, } = useSession()
    const navigate = useNavigate()
    const paths = usePathsNavigator()
    const [displayed, setDisplayed,] = useState(false)

    const arrowImg = displayed ? arrow_down : arrow_right

    const handleOnContinue = () => {
        navigate(isLogged ? paths("section") : paths("login"))
    }

    const handleOnDisplayed = () => {
        setDisplayed(!displayed)
        if (!displayed) {
            scrollToElement()
        }
    }

    return (
        <Layout>
            <PageTitle>¡Bienvenido/a!</PageTitle>
            <Section>
                <IntroText>
                    filas <BlodSpan>virtuales</BlodSpan> es un beneficio que permite realizar
                    pedidos a las secciones de los supermercados de manera virtual.
                </IntroText>
                <IntroText>
                    ¡Es muy fácil! Sólo seguis los siguientes pasos:
                </IntroText>
            </Section>
            <Section>
                {processItems.map(step =>
                    <IntroductionSteps key={step.step}>
                        <StepDivIcon>
                            <StepIcon src={step.icon} />
                        </StepDivIcon>
                        <StepContent>{step.desc}</StepContent>
                    </IntroductionSteps>
                )}
            </Section>
            <ProcessDiv ref={elementRef}>
                <ProcessTextDiv
                    onClick={handleOnDisplayed}
                >
                    <ArrowImg src={arrowImg} />
                    <ProcessText>¿cómo ser parte del programa de fidelización?</ProcessText>
                </ProcessTextDiv>
                {displayed && <StepsContainer>

                    {loyaltySteps.map(i =>
                        <StepDiv key={i.step}>
                            <StepDivIcon>
                                <StepIcon src={i.icon} />
                            </StepDivIcon>
                            <StepDesc>{i.desc}</StepDesc>
                        </StepDiv>
                    )}
                </StepsContainer>}
            </ProcessDiv>
            <ButtonWrapper>
                <ArrowButton
                    type="button"
                    onClick={handleOnContinue}
                />
            </ButtonWrapper>
        </Layout>
    )
}

export default Welcome