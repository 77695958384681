/* eslint-disable max-lines-per-function */
import React from "react"
import { ConsumerOrder, } from "gdu-vq-common/src/api/interfaces/consumer/models/ConsumerOrder"
import { StoreSection } from "gdu-vq-common/src/api/interfaces/consumer/models/ConsumerStore"
import {
    OrderState as OrderStateEnum,
} from "gdu-vq-common/src/api/interfaces/shared/models/Order"
import {
    Message,
} from "./styles/OrderStyle"
import { OrderProducts } from "api/ApiClient"
import { useToaster } from "components/providers/ToastProvider"
import ConfirmOrderCancel from "./ConfirmOrderCancel"
import LoadingModal from "components/displays/LoadingModal"
import OrderCard from "./OrderCard"

type propsType = {
    order: ConsumerOrder,
    section: StoreSection | undefined,
}


const Order = (props: propsType) => {
    const { order, } = props

    const toaster = useToaster()
    const [loading, setLoading,] = React.useState(false)
    const [displayConfirmCancelOrder, setDisplayConfirmCancelOrder,] = React.useState(false)

    const cancelHandler = async (orderId: string) => {
        try {
            setLoading(true)
            const r = await OrderProducts.cancelOrder("POST", {
                orderId,
            })
            setLoading(false)
            if (!r.ok) {
                toaster.toast({
                    title: "Error",
                    content: <Message>Ha ocurrido un error cancelando
                        la orden, vuelve a intentarlo más tarde
                    </Message>,
                })
            }
        } catch (e) {
            setLoading(false)
            toaster.toast({
                title: "Error",
                content: <Message>Ha ocurrido un error cancelando
                    la orden, vuelve a intentarlo más tarde
                </Message>,
            })
        }
    }

    const cancelOrder = order.state === OrderStateEnum.completed
        ? undefined
        : () => setDisplayConfirmCancelOrder(true)

    return <>
        <OrderCard
            order={order}
            showSubTotal={false}
            cancelOrder={cancelOrder}
        />
        {loading && <LoadingModal />}
        {
            displayConfirmCancelOrder && <ConfirmOrderCancel
                openModal={displayConfirmCancelOrder}
                closeModal={() => setDisplayConfirmCancelOrder(false)}
                confirmCancel={() => cancelHandler(order.id)}
            />
        }
    </>
}

export default Order
