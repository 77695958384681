import React from "react"
import {
  Content, SubTitle, Title, Icon, BackToStart
} from "./styles/ErrorModalStyles"
import error from "assets/icons/error.svg"
import useSession from "hooks/useSession"
import Modal from "react-modal"
import ModalComp from "./ModalComp"
import { Modals } from "styles/ModalStyle"
import { getStoreBrand, usePathsNavigator } from "util/paths"

Modal.setAppElement("#root")

const ErrorModal = () => {
  const path = usePathsNavigator()
  const { isLogged, } = useSession()

  const contentRender = () => {
    if (isLogged) {
      const { brand, storeSlug, } = getStoreBrand()
      const handleOnClick = () => (window.location.href = path("section", { storeSlug, brand, }))
      return <Content onClick={handleOnClick} >
        <Icon src={error} />
        <Title>¡Algo salió mal!</Title>
        <SubTitle>Por favor, intenta nuevamente.</SubTitle>
        <BackToStart onClick={handleOnClick}>Volver al inicio</BackToStart>
      </Content>
    } else {
      return <Content>
        <Icon src={error} />
        <Title>¡Algo salió mal!</Title>
        <SubTitle>Vuelva a scannear el codigo QR</SubTitle>
      </Content>
    }
  }

  return (
    <ModalComp modalStyle={Modals.unStyled} modalIsOpen={true}>
      {contentRender()}
    </ModalComp>
  )
}

export default ErrorModal