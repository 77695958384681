import React from "react"
import ModalComp from "components/displays/ModalComp"
import { Modals } from "styles/OldModalStyle"
import { usePathsNavigator } from "util/paths"
import { useNavigate } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import { selectedSectionIdAtom } from "state"
import {
    ConfirmButton, ConfirmMsg, ContainerConfirmModal,
} from "./style/ConfirmSectionreceptionStyle"

const ConfirmSectionReceptionDisable = (props: {
    setSectionDisable: (disable: boolean) => void,
    setRefresh: (refresh: boolean) => void,
}) => {
    const setSectionId = useSetRecoilState(selectedSectionIdAtom)
    const path = usePathsNavigator()
    const navigate = useNavigate()

    const handleOnDone = () => {
        props.setSectionDisable(false)
        setSectionId(null)
        props.setRefresh(true)
        navigate(path("section"), { replace: true, })
    }

    return (
        <ModalComp
            modalIsOpen={true}
            modalStyle={Modals.confirm}
            closeModal={handleOnDone}
        >
            <ContainerConfirmModal>
                <ConfirmMsg>
                    La sección fue deshabilitada. Disculpe las molestias.
                </ConfirmMsg>
                <ConfirmButton
                    onClick={handleOnDone}
                >
                    Entendido
                </ConfirmButton>
            </ContainerConfirmModal>
        </ModalComp>
    )
}

export default ConfirmSectionReceptionDisable