import styled, { css } from "styled-components"

export const Container = styled.div<{ disable: boolean, }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    min-width: 61px;
    ${props => props.disable && css`
        opacity: 0.3;
        pointer-events:none; 
    `}
`

export const Label = styled.p`
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-family: Silka;
    font-weight: 800;
`

export const Badge = styled.div`
    position: absolute;
    top: -2px;
    right: -3px;
    background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 800;
    height: 16px;
    width: 21px;
    border-radius: 20px;
    font-size: 13px;
`