/* eslint-disable max-lines-per-function */
import React, { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Title, Description } from "../getStarted/styles/GetStartedStyles"
import Button from "../../components/displays/Button"
import {
    SubmitHandler,
    useImmerFormYup,
} from "immer-form/Validators/YupValidator"
import { SessionClient } from "api/ApiClient"
import { UserInfo } from "types"
import useSession from "hooks/useSession"
import { getDefaultValues, LoginForm, useFormValidation } from "./helpers"
import { usePathsNavigator } from "util/paths"
import LoadingModal from "components/displays/LoadingModal"
import LoginError from "./LoginError"
import { InputField } from "components/fields/inputs/Input"
import { StyledForm } from "./styles/LoginStyles"
import { trackEvent } from "util/MixPanelEventsHelper"



const Document = () => {
    const [loading, setLoading,] = useState(false)
    const { handleInitSession, } = useSession()
    const paths = usePathsNavigator()
    const navigate = useNavigate()
    const { state, } = useLocation()
    const redirectPath: string | null = state
    const [displayUnregisteredUser, setDisplayUnregisteredUser,] = useState(false)
    const [serverError, setServerError,] = useState(false)

    const {
        token,
        handleSubmit,
        formState: {
            submittable,
        },
    } = useImmerFormYup<LoginForm>({
        defaults: getDefaultValues(),
        validator: useFormValidation(),
    })
    const onSubmit: SubmitHandler<LoginForm> = async ({ ci, }) => {
        setLoading(true)

        try {
            const response = await SessionClient.register("POST", { identity: ci, })
            if (response.ok) {
                if (response.content.isLoyal) {
                    const userSession: UserInfo = {
                        userName: response.content.fullName,
                        token: response.content.token,
                        userCI: ci,
                    }
                    handleInitSession(userSession)
                    trackEvent.login({ ci, })
                    if (redirectPath) {
                        navigate(redirectPath, { replace: true, })
                    } else {
                        navigate(paths("section"), { replace: true, })
                    }
                } else {
                    setDisplayUnregisteredUser(true)
                }
            } else {
                setServerError(true)
            }
            setLoading(false)

        } catch (displayUnregisteredUser) {
            setLoading(false)
            setServerError(true)
        }
    }

    return (
        <React.Fragment>
            <Title>Ingresar</Title>
            <Description>
                Ingresa tu documento de identidad para verificar que sos <b>cliente fidelizado.</b>
            </Description>
            <StyledForm style={{ width: "100%", }} onSubmit={handleSubmit(onSubmit)}>
                <InputField

                    error={displayUnregisteredUser || serverError
                        ? <LoginError
                            serverError={serverError} />
                        : null}
                    type="text"
                    disabled={false}
                    onFocus={() => setDisplayUnregisteredUser(false)}
                    token={token}
                    attr="ci"
                    placeholder="Documento de identidad"
                />
                <Button
                    disabled={!submittable}
                    primary={true}
                    label="Realizar pedido"
                    type="submit"
                />

            </StyledForm>
            {loading ? <LoadingModal
                isOpen={true}
            /> : <></>}
        </React.Fragment>
    )
}

export default Document
