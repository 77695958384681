/* eslint-disable max-len */
import React from "react"

import Button from "components/displays/Button"
import { ConfirmModalContent } from "components/displays/Elements"
import { DefaultTheme, withTheme } from "styled-components"
import { Warning } from "./ConfirmSectionChangeStyles"
import { SECTIONS } from "components/constants/sections"
import { SectionType } from "gdu-vq-common/src/api/interfaces/shared/models/Section"
import Modal from "components/displays/Modal"

type PropsType = {
    currentSection: SectionType,
    newSection: SectionType,
    itemsInCart: number,
    onCancel: () => void,
    onDone: () => void,
    theme: DefaultTheme,
}
const ConfirmSectionChange = ({ onCancel,
    onDone,
    theme,
    newSection,
    itemsInCart,
    currentSection, }: PropsType) => {

    const checkIfPlural = () => {
        if (itemsInCart > 1) {
            return "s"
        }
    }

    return <Modal.Wrapper>
        <ConfirmModalContent>
            <div style={{ marginBottom: 15, }}>
                <Modal.Title>
                    Cambio de sección
                </Modal.Title>
                <Modal.Description>
                    <p>
                        Tenés <span>{itemsInCart} item{checkIfPlural()} de {SECTIONS[currentSection]}</span> en el carrito. </p>
                    <Warning>¡Lo{checkIfPlural()} perderás si cambias a {SECTIONS[newSection]}!</Warning>
                </Modal.Description>
            </div>
            <Modal.ButtonsWrapper >
                <Button primary
                    onClick={onDone}
                    label={`Cambiar a ${SECTIONS[newSection]}`}
                    color={theme.palette.common.red2}
                />
                <Button
                    onClick={onCancel}
                    primary={false}
                    label="Cancelar" />
            </Modal.ButtonsWrapper>
        </ConfirmModalContent>
    </Modal.Wrapper>
}

export default withTheme(ConfirmSectionChange)