import styled from "styled-components"

export const InputTextStyled = styled.textarea`
    display: block;
    width: 100%;
    max-width: 100%;
    height: 61px;
    max-height: 80px;
    font-size: 15px;
    padding: 18px 16px;
    margin-bottom: 10px;
    background-color: ${({ theme, }) => theme.palette.common.white};
    border-radius: 14px;
    border: 1px solid ${({ theme, }) => theme.palette.common.grey7};
    resize: none;
    outline: none;
    :focus{
        resize: none;
    }
`