import React from "react"
import { Wrapper } from "./styles/ButtonStyles"

type PropsType = {
  children: React.ReactNode,
  outline?: boolean,
  small?: boolean,
  disabled?: boolean,
  onClick?: (e: React.MouseEvent) => void,
  style?: React.CSSProperties,
};

const Button = (props: PropsType) => {
  return <Wrapper {...props}>
    {props.children}
  </Wrapper>
}

export default Button