import {
    StoreSection,
    StoreSectionProducts,
} from "gdu-vq-common/src/api/interfaces/consumer/models/ConsumerStore"
import { SucursalLocation } from "gdu-vq-common/src/api/interfaces/shared/models/Sucursal"
import { ObjDictionary } from "gdu-vq-common/src/helpers/toDictionary"

export enum Brands {
    disco = "disco",
    devoto = "devoto",
    geant = "geant",
}

export type ProductsSelector = {
    products: StoreSectionProducts[],
    productsById: ObjDictionary<string, StoreSectionProducts>,
    lastUpdate?: Date | null,
}

export type CartItem = {
    productId: string,
    quantity: number,
    comment?: string,
}

export type Cart = {
    sectionId: string | null,
    cartItems: CartItem[],
}

export type StoreInfo = {
    id: string,
    description: string,
    location: SucursalLocation,
    sections: StoreSection[],
}

export type BranchOffice = {
    id: number,
    region: string,
    location: string,
    type: string,
    name: string,
    officeSlug: string,
    sections: string[],
}

export type Supermarket = {
    id: number,
    supermarket: string,
    slug: string,
    branchOffices: BranchOffice[],
};

export type UserInfo = {
    userName: string,
    token: string,
    userCI: string,
}
