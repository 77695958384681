import React from "react"
import { Modals } from "styles/ModalStyle"
import ModalComp from "./ModalComp"
import {
  Content, SubTitle, Title, Icon
} from "./styles/ErrorModalStyles"
import error from "assets/icons/error.svg"

const InvalidStoreModal = ({
  isOpen = true,
  onClose, }: { isOpen?: boolean, onClose?: () => void, }
) => {
  return (
    <ModalComp
      modalIsOpen={isOpen}
      modalStyle={Modals.unStyled}
      closeModal={() => { onClose ? onClose() : () => { } }}
    >
      <Content>
        <Icon src={error} />
        <Title>¡Algo salió mal!</Title>
        <SubTitle>La dirección ingresada no existe</SubTitle>
      </Content>
    </ModalComp>)
}

export default InvalidStoreModal