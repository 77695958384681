import React from "react"
import { Route, useNavigate } from "react-router-dom"
import {
    StoreSectionProducts,
} from "gdu-vq-common/src/api/interfaces/consumer/models/ConsumerStore"
import ShoppingCartLines from "./showCart/ShoppingCartLines"
import RootRoutes from "components/utils/GenerateAbsolutePath"
import { pathsDefinition, usePathsNavigator } from "util/paths"
import { productsSelector } from "state"
import { useRecoilValueLoadable } from "recoil"
import { useLoadableValueHelper } from "hooks/useLoadableValueHelper"
import LoadingModal from "components/displays/LoadingModal"
import ProductDetailModal from "./ProductDetailModal"

const ShoppingCart = () => {
    const navigate = useNavigate()
    const paths = usePathsNavigator()
    const response = useLoadableValueHelper(useRecoilValueLoadable(productsSelector))

    if (response.loading) { return <LoadingModal isOpen={true} /> }

    const goToProductDetail = (product: StoreSectionProducts | undefined) => {
        if (product) {
            navigate(paths("shoppingItemDetail", { "product_id": product.id, }))
        } else {
            navigate(paths("shoppingCart"))
        }
    }
    return <div style={{ padding: 16, }}>
        <ShoppingCartLines
            goProductDetail={(product) => goToProductDetail(product)}
        />
        <RootRoutes>
            <Route path={pathsDefinition.shoppingItemDetail} element={
                <ProductDetailModal />
            } />
        </RootRoutes>
    </div>
}

export default ShoppingCart