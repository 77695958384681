import styled from "styled-components"

export const CustomizedDiv = styled.div`
  position: fixed;
  bottom: 77px;
  width: 100%;
  padding: 0 16px 0 16px;
  left: 0;
  background: rgba(246, 249, 251, 0.83);
  backdrop-filter: blur(15px);
`

export const EstimatedDiv = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid ${({ theme, }) => theme.palette.common.grey7};
  border-bottom: 1px solid ${({ theme, }) => theme.palette.common.grey7};
  width: 110%;
  margin-left: -5%;
`

export const EstimatedPriceDiv = styled.div`
  width: 45.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const EstimatedTextP = styled.p`
  font-size: 14px;
  font-weight: 800;
  text-align: center;
  text-transform: none;
  color: ${({ theme, }) => theme.palette.primary.main};
  line-height: 130%;
  letter-spacing: -0.15px;
  font-family: 'Silka';
  font-style: normal;
  letter-spacing: -0,2em;
`

export const PriceP = styled.p`
  height: 35px;
  font-size: 27px;
  color: ${({ theme, }) => theme.palette.primary.main};
  text-align: right;
  line-height: 120%;
  letter-spacing: -0.5px;
  font-weight: 600;
  display: flex;

  p {
    font-size: 15px;
    position: relative;
    top: 5px;
  }
`

export const DividerCarrito = styled.div`
  width: 1px;
  height: 63px;
  background: ${({ theme, }) => theme.palette.common.grey7};
`
export const EstimatedTimeDiv = styled.div`
  width: 45.5%;
  display: flex;
  justify-content: center;
  align-items: center;
`



export const CartItemsWrapper = styled.h2`
  position: relative;
  top: 33px;

`