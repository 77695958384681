import { motion } from "framer-motion"
import styled from "styled-components"

export const Container = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative ;
    left: 0;
    top:0;
    height: 100%;
    gap: 8px;
    box-sizing: border-box;
    @media screen and (max-height: 650px) {

        img{
            height:111px;
        }
    }

    .imgWrapper {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`