
import styled from "styled-components"
import { motion } from "framer-motion"


export const Container = styled(motion.div)`
    height: 100vh;
    height: 100%;
    display: flex;
    font-family: Silka;
    flex-direction: column;
    background-color: ${({ theme, }) => theme.palette.primary.contrastText};
    padding:16px;
`


export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
`

export const HeaderText = styled.p`
    font-family: Silka;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.02em;
    line-height: 140%;
    color: ${({ theme, }) => theme.palette.primary.main};
`

export const Title = styled.div`
    font-size: 30px;
    font-weight: 800;
`

export const Description = styled.div`
    
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 140%;
    color: ${({ theme, }) => theme.palette.common.grey14};
    text-align: center;
    b{
        font-weight: 700;
    }
`

export const Scroll = styled(motion.div)`
    position: relative;
    overflow: hidden;
    height: 100%;
    flex: 1;
`

export const Actions = styled.div`
    padding: 16px 0;
    box-sizing: border-box;
    display: flex;
    gap: 8px;
    margin-top:4px;
    flex-direction: column;
`
export const Welcome = styled.div`
    display: flex;
    align-items:center;
    flex-direction: column;
    justify-content: space-between;

`

export const WelcomeContent = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    & img {
        display: inline-block;
        height: 22px;
    }
`