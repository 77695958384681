import React from "react"

import {
  OrderState as OrderStateEnum,
} from "gdu-vq-common/src/api/interfaces/shared/models/Order"
import { Count, LabelWrapper, Wrapper } from "./styles/OrderStateStyle"
type PropsType = {
  state: OrderStateEnum,
  expanded?: boolean,
  amount: number,
  onClick?: () => void,
}

const stateLabel = {
  [OrderStateEnum.onHold]: "En espera",
  [OrderStateEnum.canceled]: "Cancelado",
  [OrderStateEnum.inProgress]: "En preparacion",
  [OrderStateEnum.completed]: "Completados",
  [OrderStateEnum.canceledInProgress]: "Cancelado en progreso",
}


const OrderState = ({ amount, state, }: PropsType) => (
  <Wrapper state={state}>
    <LabelWrapper>
      {stateLabel[state]}
    </LabelWrapper>
    <Count>
      {amount}
    </Count>
  </Wrapper>
)

export default OrderState