import React from "react"

import { Content, } from "../displays/styles/ToastStyles"

import { Duration } from "components/providers/ToastProvider"

type PropsType = {
  onClick: () => void,
  content: React.ReactNode,
  duration: Duration,
}

const Toast = ({ content, onClick, duration, }: PropsType) => {
  const [contentState, setContentState,] = React.useState(true)

  React.useEffect(() => {
    setTimeout(() => {
      setContentState(false)
    }, duration - 300)
  }, [])


  return (
    <Content onClick={onClick} contentState={contentState}>
      {content}
    </Content>)
}
export default Toast