
import React from "react"
import Button from "components/displays/Button"
import { DefaultTheme, withTheme } from "styled-components"
import { ConfirmModalContent } from "components/displays/Elements"
import Modal from "components/displays/Modal"



const ConfirmOrder = ({ cancel, confirm, theme, }: {
    cancel: (c: boolean) => () => void,
    confirm: () => void,
    theme: DefaultTheme,
}) => {

    return (
        <Modal.Wrapper>
            <ConfirmModalContent>
                <Modal.Title>
                    ¿Tu pedido está listo?
                </Modal.Title>
                <Modal.ButtonsWrapper >
                    <Button primary={false}
                        onClick={confirm}
                        label={"Enviar pedido"}
                    />
                    <Button
                        onClick={cancel(false)}
                        primary
                        color={theme.palette.common.red2}
                        label="Volver atrás" />
                </Modal.ButtonsWrapper>
            </ConfirmModalContent>
        </Modal.Wrapper>
    )
}

export default withTheme(ConfirmOrder)