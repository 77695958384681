/*
Fuente:
https://github.com/remix-run/react-router/issues/8035#issuecomment-997737565
*/

import React, { useContext, useMemo } from "react"
import { Routes, UNSAFE_RouteContext as RouteContext, } from "react-router-dom"

const RootRoutes = (prosp: React.ComponentProps<typeof Routes>) => {

    const ctx = useContext(RouteContext)

    const value = useMemo(
        () => ({
            ...ctx,
            matches: [],
        }),
        [ctx,]
    )

    return <RouteContext.Provider
        value={value}
    >
        <Routes {...prosp} />
    </RouteContext.Provider>

}

export default RootRoutes