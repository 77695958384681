import React from "react"
import { useNavigate } from "react-router-dom"
import { StoreSection } from "gdu-vq-common/src/api/interfaces/consumer/models/ConsumerStore"
import Sections from "../sectionList/SectionsList"
import { cartAtom, selectedSectionIdAtom, assertStoreSelector } from "state"
import { useRecoilValueLoadable, useSetRecoilState, } from "recoil"
import { useLoadableValueHelper } from "hooks/useLoadableValueHelper"
import LoadingModal from "components/displays/LoadingModal"
import NotFound from "pages/notFound/NotFound"
import { usePathsNavigator } from "util/paths"
import { useTrackEvent } from "hooks/useTrackEvent"

const SectionSelect = () => {
    const navigate = useNavigate()
    const setSelectedSectionId = useSetRecoilState(selectedSectionIdAtom)
    const setCartAtom = useSetRecoilState(cartAtom)
    const paths = usePathsNavigator()
    const { selectSection, } = useTrackEvent()
    const responseStore = useLoadableValueHelper(useRecoilValueLoadable(assertStoreSelector))

    if (responseStore.error) { throw new Error("Invalid Store") }

    const handleChangeSection = (selectedSection: StoreSection) => {
        const section = responseStore.data?.sections.find(s => s.id === selectedSection.id)
        setCartAtom({
            sectionId: section!.id,
            cartItems: [],
        })
        setSelectedSectionId(section!.id)
        selectSection(section!.sectionType,)
        navigate(paths("products"))
    }
    if (responseStore.loading) {
        return <LoadingModal isOpen={true} />
    }

    return (
        responseStore.data.sections.length > 0
            ? (<Sections
                onClick={(section: StoreSection) => handleChangeSection(section)}
                store={responseStore.data!}
            />)
            : (<NotFound msg="Este supermercado no dispone de secciones" />)
    )
}

export default SectionSelect