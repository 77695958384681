import { Styles } from "react-modal"
import { MODALS_ZINDEX } from "./GlobalStyles"

const screen: Styles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "hsl(0deg 0% 38% / 0.7)",
        zIndex: MODALS_ZINDEX,
    },
    content: {
        position: "absolute",
        overflow: "auto",
        border: "none",
        WebkitOverflowScrolling: "touch",
        borderRadius: "13px 13px 0 0",
        outline: "none",
        padding: "23px 16px",
        backgroundColor: "rgba(250, 250, 250, 0.93)",
        backdropFilter: "blur(80px)",
        fontFamily: "Silka",
        inset: "auto 0 0",

    },
}

const confirm: Styles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "hsl(0deg 0% 38% / 0.7)",
        zIndex: MODALS_ZINDEX,

    },
    content: {
        maxBlockSize: "max-content",
        textAlign: "center",
        position: "absolute",
        minHeight: "207px",
        height: "fit-content",
        WebkitOverflowScrolling: "touch",
        borderRadius: "20px",
        outline: "none",
        padding: "22px 8px",
        inset: "auto 8px 8px",

    },
}

const unStyled: Styles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.35)",
        zIndex: MODALS_ZINDEX,
    },
    content: {
        position: "absolute",
        top: "50%",
        transform: "translateX(-50%) translateY(-50%)",
        left: "50%",
        border: "none",
        width: "fit-content",
        height: "fit-content",
        background: "unset",
        overflow: "unset",

    },
}

const screenNoPadding = { ...screen, content: { ...screen.content, padding: 0, }, }

export const Modals = {
    screen,
    confirm,
    unStyled,
    screenNoPadding,
}