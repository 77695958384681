import { useMemo } from "react"
import * as yup from "yup"

export type LoginForm = { ci: string, }

const errorMessage = "EL FORMATO DEL DOCUMENTO DE IDENTIDAD INGRESADO NO ES VÁLIDO."

export const useFormValidation = () => {
    return useMemo(() => {
        const schema: yup.SchemaOf<LoginForm> = yup.object().shape({
            ci: yup.string()
                .ensure()
                .matches(/^[0-9]*$/, errorMessage)
                .min(7, errorMessage)
                .max(15, errorMessage),
        })
        return schema
    }, [])
}

export const getDefaultValues = () => ({ ci: "", })