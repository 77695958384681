import React from "react"
import { Container } from "./styles/ButtonStyles"

interface Props {
    primary: boolean;
    secondary?: boolean;
    withoutBorder?: boolean;
    label: string | React.ReactNode;
    onClick?: () => void;
    type?: string;
    disabled?: boolean;
    color?: string;
}

const Button = (props: Props) => {
    const withoutBorder = !!props?.withoutBorder
    return (
        <Container
            whileTap={props.disabled ? {} : { scale: 0.95, opacity: 0.8, }}
            withoutBorder={withoutBorder}
            onClick={props.disabled ? undefined : props.onClick}
            layout
            color={props.color}
            disabled={props.disabled}
            primary={props.primary}>
            {props.label}
        </Container>
    )
}

export default Button