import styled from "styled-components"

export const Text = styled.p`
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
`

export const ButtonWrapper = styled.div`
  text-align: center;
  width: calc(100% - 32px);
  bottom: 16px;
  position: absolute;
`

export const TextWrapper = styled.div`
  height: calc(100% - 120px);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`