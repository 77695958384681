import styled from "styled-components"

export const ConfirmMsg = styled.p`
    text-align: center;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.3px;
    margin-bottom: 36px;
`

export const ConfirmButton = styled.button`
    width: 100%;
    font-size: 12px;
    padding: 8px 4px;
    border-radius: 8px;
    line-height: 140%;
    letter-spacing: -0.3px;
    background-color: ${({ theme, }) => theme.palette.common.black};
    color: ${({ theme, }) => theme.palette.common.offWhite};
    border: 1px solid transparent;
`

export const ContainerConfirmModal = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    padding: 24px 0px;
`