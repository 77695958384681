import React from "react"
import { ImgWrapper, Wrapper } from "./styles/InlineNotificationStyles"
import { ReactComponent as WarningIcon } from "assets/icons/warning.svg"
import { ReactComponent as InformationIcon } from "assets/icons/informationIcon.svg"

export enum NotificationType {
  error = "error",
  information = "information"
}

type PropsType = {
  children: React.ReactNode,
  notificationType: NotificationType,
};


const InlineNotification = ({ children, notificationType, }: PropsType) => {

  const renderIcon = () => {
    switch (notificationType) {
      case NotificationType.error:
        return <ImgWrapper>
          <WarningIcon />
        </ImgWrapper>
      case NotificationType.information:
        return <ImgWrapper>
          <InformationIcon style={{ rotate: "x 180deg", }} />
        </ImgWrapper >
      default:
        return <></>
    }
  }

  return <Wrapper notificationType={notificationType}>
    {renderIcon()}
    {children}
  </Wrapper>

}

export default InlineNotification