/* eslint-disable max-lines-per-function */
import React from "react"
import produce, { original } from "immer"
import Toast from "./Toast"
import { Wrapper } from "components/displays/styles/ToastStyles"



const MAX_ACTIVE_TOASTS = 4

export enum Duration {
  short = 5000,
  long = 10000
}

type ToastType = {
  title: string,
  content: React.ReactNode,
  duration: Duration,
}

type InputToastType = {
  title: string,
  content: React.ReactNode,
  duration?: Duration,
}

type ToastWithId = ToastType & {
  id: string,
}

type ToastWithTimeout = ToastWithId & {
  timeoutToClear: ReturnType<typeof setTimeout>,
}
type ToastProviderState = {
  activeToasts: ToastWithTimeout[],
  pendingToast: ToastWithId[],
}

type ToastContextType = {
  toast: (toast: InputToastType) => () => void,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ToastContext = React.createContext<ToastContextType>(null as any)


export const useToaster = () => {
  const context = React.useContext(ToastContext)
  return context
}

type ToasterProviderProps = {
  children: React.ReactNode,
}

const ToastProvider = (props: ToasterProviderProps) => {
  const [state, setState,] = React.useState<ToastProviderState>({
    activeToasts: [],
    pendingToast: [],
  })

  const context: ToastContextType = React.useMemo(() => ({
    toast: ({
      title,
      content,
      duration = Duration.long,
    }: InputToastType) => {
      const toast = { title, content, duration, }
      const toastWithId = {
        ...toast,
        id: Math.random().toString(36)
          .substr(2, 9),
      }
      setState(produce(sd => {
        if (sd.activeToasts.length < MAX_ACTIVE_TOASTS && sd.pendingToast.length === 0) {
          sd.activeToasts.push(getWithTimeout(toastWithId))
        } else {
          sd.pendingToast.push(toastWithId)
        }
      }))
      return closeToast(toastWithId)
    },
  }), [])


  const closeToast = (toast: ToastWithId) => () => {
    setState(produce(sd => {
      const toRemove = sd.activeToasts.find(t => t.id === toast.id)
      if (toRemove) {
        clearTimeout((toRemove as ToastWithTimeout).timeoutToClear)
        sd.activeToasts = sd.activeToasts.filter(t => t.id !== toast.id)
      } else {
        sd.pendingToast = sd.pendingToast.filter(t => t.id !== toast.id)
      }
    }))
  }

  React.useEffect(() => {
    return () => {
      state.activeToasts.forEach(toast => clearTimeout(toast.timeoutToClear!))
    }
  }, [])


  React.useEffect(() => {
    if (state.activeToasts.length < MAX_ACTIVE_TOASTS && state.pendingToast.length > 0) {
      setState(produce(sd => {
        const head = sd.pendingToast.shift()!
        const newToastWithTimeout = getWithTimeout(original(head)!)
        sd.activeToasts.push(newToastWithTimeout)
      }))
    }
  }, [state,])

  const getWithTimeout = (toast: ToastWithId) => {
    const ret = {
      ...toast,
      timeoutToClear: setTimeout(() => {
        closeToast(ret)()
      }, toast.duration),
    }
    return ret
  }

  return (
    <ToastContext.Provider value={context}>
      {props.children}
      <Wrapper>
        {state.activeToasts.map(toast => {
          return <Toast
            key={toast.id}
            {...toast}
            onClick={closeToast(toast)}
          />
        })}
      </Wrapper>
    </ToastContext.Provider >


  )
}
export default ToastProvider
