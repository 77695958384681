import styled, { css } from "styled-components"


type InputWrapperPropsType = {
    focus?: boolean,
}
export const InputWrapper = styled.div<InputWrapperPropsType>`
    width: 100%;
    position:relative ;

    label {
        position:absolute;
        font-weight: 500;
        font-size: 16px;
        top:50%;
        left:50%;
        transform: translateY(-50%) translateX(-50%);
        width: max-content;
        padding: 0 5px;

        text-align: center;
        color: ${({ theme, }) => theme.palette.common.grey7};

        transition:all 0.3s;

        ${({ focus, }) => {
        return focus && css`
            transform: translateY(-165%) translateX(-75%);
            color:${({ theme, }) => theme.palette.primary.main};
            background-color: #fafafa;
            `
    }}
    }
`

type StyledInputPropsType = {
    error?: boolean,
}
export const StyledInput = styled.input <StyledInputPropsType>`
    height: 60px;
    padding: 0 20px;
    margin: 4px 0;
    outline: 2px solid transparent;
    outline-offset: 2px;
    border: 1px solid ${({ theme, }) => theme.palette.common.grey7};
    border-radius: 14px;
    background-color: ${({ theme, }) => theme.palette.common.white};
    width:100%;
    color: ${({ theme, }) => theme.palette.primary.main};
    font-weight: 600;
    font-size: 18px;
    ${({ error, theme, }) => error && css` border: 2px solid ${theme.palette.old.error.main};`}
    text-align: center;
    :hover{
        border: 3px solid ${({ theme, }) => theme.palette.primary.main};
    }
    &:disabled {
        background-color: ${({ theme, }) => theme.palette.common.grey3};
    }
`