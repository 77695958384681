/* eslint-disable no-process-env */
export const isProduction = process.env.NODE_ENV === "production"
export const isDevelopment = process.env.NODE_ENV === "development" || !process.env.NODE_ENV

const envType
    = (
        (isProduction && (process.env.REACT_APP_ENV_TYPE || "prod"))
        || (isDevelopment && "dev-local")
    ) as string

const buildName = `${envType}-${process.env.REACT_APP_VERSION || "Neutral"}`
const apiCodeCore = process.env.REACT_APP_API_CORE_CODE || ""
const sentryDSN = "https://181851a1017148f889ac933b51bd8626@o256449.ingest.sentry.io/6258336"

const sentryIntegrationsSampleRate = (
    (process.env.REACT_APP_SENTRY_INTEGRATIONS_SAMPLERATE
        && parseInt(process.env.REACT_APP_SENTRY_INTEGRATIONS_SAMPLERATE, 10)
    ) || 0.1
)

const mixpanelId = process.env.REACT_APP_MIXPANEL_ID

export const EnvHelper = {
    baseUrlCore: process.env.REACT_APP_API_CORE_BASE_URL!, // la url NO debe terminar con /
    apiCodeCore,
    baseUrlRT: process.env.REACT_APP_API_RT_BASE_URL!, // la url NO debe terminar con /
    isProduction,
    envType: envType,
    buildName,
    sentryDSN,
    sentryIntegrationsSampleRate,
    mixpanelId,
}


