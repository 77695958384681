/* eslint-disable max-lines-per-function */
import React, { useState } from "react"
import { CartItem } from "types"
import { StoreSectionProducts } from
  "gdu-vq-common/src/api/interfaces/consumer/models/ConsumerStore"
import {
  calculatePrice,
  incrementToReadable,
  productReadablePriceCarrito,
} from "gdu-vq-common/src/api/interfaces/shared/helpers/ProductUtil"
import {
  CartItemDiv,
  ProductImgWrapper,
  ContainerDiv,
  ButtonsDiv,
  DeleteButton,
  EditButton,
  PriceP,
  DescriptionP,
  ProductAmountWrapper,
  DivDivider,
  FlexDirection,
  DivGr,
} from "../Style/ShoppingShowCartStyle"
import ConfirmDeleteItem from "./ConfirmDeleteItem"
import { useRecoilValueLoadable } from "recoil"
import { sectionSelectAtom } from "state"
import { useLoadableValueHelper } from "hooks/useLoadableValueHelper"
import ProductImg, { ImgType } from "util/ProductImg"
import { ReactComponent as EditIcon } from "assets/icons/editIcon.svg"
import { ReactComponent as TrashIcon } from "assets/icons/trashIcon.svg"


const ShoppingCartItem = (props: {
  cartItem: CartItem,
  productItem: StoreSectionProducts,
  onClick: () => void,
}) => {
  const [confirmModal, setConfirmModal,] = useState<boolean>(false)
  const { description, price, imageUrl, unit, increments, }
    = props.productItem
  const { quantity, productId, } = props.cartItem
  const section = useLoadableValueHelper(
    useRecoilValueLoadable(sectionSelectAtom)
  )

  const { unit: readableUnit, } = incrementToReadable(increments, unit, quantity, true)
  const renderProductButton = () => {
    return (
      <ButtonsDiv>
        <DeleteButton onClick={() => setConfirmModal(true)}>
          <TrashIcon />
          <p>eliminar</p>
        </DeleteButton>
        <EditButton onClick={props.onClick}>
          <EditIcon />
          <p>editar</p>
        </EditButton>
      </ButtonsDiv>
    )
  }

  return (
    <>
      <CartItemDiv>
        <ProductAmountWrapper>
          <ProductImgWrapper>
            <ProductImg
              imgStyle={ImgType.productShoppingLine}
              sectionType={section.data!.sectionType}
              imageUrl={imageUrl}
            />
          </ProductImgWrapper>
          <DescriptionP>{description}</DescriptionP>
          <FlexDirection>
            <PriceP>
              <p>$</p>
              {productReadablePriceCarrito(
                calculatePrice(price, increments, quantity, unit)
              )}
            </PriceP>
            <DivDivider />
            <DivGr>{quantity} {readableUnit}</DivGr>
          </FlexDirection>
        </ProductAmountWrapper>
        <ContainerDiv>{renderProductButton()}</ContainerDiv>
        {confirmModal && (
          <ConfirmDeleteItem
            id={productId}
            name={description}
            openModal={confirmModal}
            closeModal={() => setConfirmModal(false)}
          />
        )}
      </CartItemDiv>
    </>
  )
}

export default ShoppingCartItem
