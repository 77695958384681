import styled from "styled-components"

export const ModalTitle = styled.p`
    font-weight: 600;
    font-size: 20px;
    line-height: 131%;
    letter-spacing: -0.02em;
    color: #333333;
    padding-bottom: 19px;
`
export const Hr = styled.hr`
    height: 1px;
    color: #E0E0E0;
    margin-bottom: 13px;
`