import styled, { keyframes } from "styled-components"



const enterAnimation = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100%{
    opacity: 1;
    transform: translateY(0);
  }
`

const leaveAnimation = keyframes`
  0%{
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
`

export const Wrapper = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
`
type ContentPropsType = {
  contentState: boolean,
}
export const Content = styled.div < ContentPropsType>`
  display: flex;
  width: 90%;
  min-height: 30px;
  background-color: #333;
  border-radius: 12px;
  margin-top: 15px;
  padding: 12px;
  box-sizing: border-box;
  color: #fff;
  /* transform: translateY(-100%); */
  animation-name: ${({ contentState, }) => (contentState ? enterAnimation : leaveAnimation)};
  animation-duration: 0.3s;
  `

export const SectionImg = styled.img`
    background-color: #D6131E;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    padding: 6px;
`
export const TextContent = styled.div`
  display: flex;
  margin-left: 12px;
  flex-direction: column;
  `
export const Location = styled.div`
  display: flex;
  align-items: center;
  `

export const SectionTitle = styled.span`
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  `
export const Brand = styled.span`
  font-size: 8px;
  text-transform: uppercase;
  margin-left: 5px;
  display:flex;
  align-items:center;
  
  `

export const Message = styled.span`
   font-size: 14px;
   
  `