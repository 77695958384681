import { useEffect, useMemo, useState, } from "react"
import { Response } from "gdu-vq-common/src/api/interfaces/shared/ResponseTypes"

type Props<T> = {
    key: string,
    func: () => Promise<Response<T>>,
}

export const useRequest = <T>(props: Props<T>) => {
    const [data, setResponse,] = useState<T>()
    const [error, setError,] = useState<boolean>(false)
    const [loading, setLoading,] = useState<boolean>(true)
    const [mutator, setMutator,] = useState<number>(0)

    useEffect(() => {
        let cancel = false;
        (async () => {
            try {
                setLoading(true)
                setError(false)
                const { content, ok, } = await props.func()
                if (cancel) {
                    return
                }
                if (ok) {
                    setResponse(content)
                } else {
                    setError(true)
                }
            } catch (e) {
                setError(true)
            }
            setLoading(false)
        })()
        return () => {
            cancel = true
        }
    }, [props.key, mutator,])

    return useMemo(() => {
        const mutate = () => {
            setMutator(i => i + 1)
        }
        if (error) {
            return {
                error: true,
                loading: false,
                data: undefined,
                mutate,
            } as const
        } else if (loading) {
            return {
                error: false,
                loading: true,
                data: undefined,
                mutate,
            } as const
        } else {
            return {
                error: false,
                loading: false,
                data: data!,
                mutate,
            } as const
        }
    }, [error, loading, data,])
}