import watch from "assets/icons/started/watch.svg"
import loadingIcon from "assets/icons/loadingIcon.svg"
import personArrow from "assets/icons/personArrow.svg"
import identifyIcon from "assets/icons/identifyIcon.svg"
import personWhite from "assets/icons/started/personWhite.svg"
import pointerSelect from "assets/icons/started/pointerSelect.svg"
import cart from "assets/icons/started/cart.svg"
import consumerData from "assets/icons/consumerData.svg"



export const processItems = [
    {
        step: 1,
        icon: personWhite,
        desc: "Ingresás con tu documento.",
    },
    {
        step: 2,
        icon: pointerSelect,
        desc: "Elegís donde hacer el pedido.",
    },
    {
        step: 3,
        icon: cart,
        desc: "Seleccionás  los productos y confirmás tu pedido.",
    },
    {
        step: 4,
        icon: watch,
        desc: "En el tiempo estimado, retirás con el código brindado.",
    },
]


export const preparationSteps = [
    {
        step: 1,
        icon: loadingIcon,
        desc: "Tu pedido será preparado en la sección en la cuál se realizó el pedido.",
    },
    {
        step: 2,
        icon: watch,
        desc: "Podrás consultar el estado del mismo en la pestaña de pedidos.",
    },
    {
        step: 3,
        icon: personArrow,
        desc: "Una vez completado, será trasladado a la estación de retiro de la sección.",
    },
    {
        step: 4,
        icon: identifyIcon,
        desc: "Podrás identificarlo con el código brindado.",
    },
]

export const loyaltySteps = [
    {
        step: 1,
        icon: personArrow,
        desc: "¡Es muy simple! Dirígite a la sección de atención al cliente de la sucursal.",
    },
    {
        step: 2,
        icon: consumerData,
        desc: "Brinda tus datos.",
    },
    {
        step: 3,
        icon: cart,
        desc: "Disfrutá de los beneficios del programa.",
    },
]