import styled, { css } from "styled-components"


export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  text-align: left;
  padding: -23px -16px;
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  padding: 23px 16px 23px 16px;
`
export const Footer = styled.div`
  flex: 1;
  overflow: auto;
`

export const Title = styled.p`
  font-size: 20px;
  font-weight: 600;
`

export const Hr = styled.hr`
  height: 1px;
  color: #E0E0E0;
`
export const SubTitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin-top: 4px;
`
export const ProductsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  `
export const OrderDetails = styled.div`
  background-color:${({ theme, }) => theme.palette.common.grey};
  border-radius: 16px;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  
  span{
    font-size: 22px;
    color: ${({ theme, }) => theme.palette.common.black};
    font-weight: bold;
  }
`
export const OrderSection = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;

  img{
    background-color: ${({ theme, }) => theme.palette.old.primary.main};
    border-radius: 4px;
    width: 16px;
    height: 16px;
    padding: 2px;
    margin-right: 6px;
  }
`

type ProductsTotalProps = {
  display: boolean,
}
export const ProductsTotal = styled.div<ProductsTotalProps>`
  background-color: ${({ theme, }) => theme.palette.common.grey};
  border-radius: 16px 16px 0 0;
  display:flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;

  p{
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    align-items: center;
    font-size: 12px;
    color: ${({ theme, }) => theme.palette.common.black};

    img{
      filter: invert(1);
      max-width: 10px;
      max-height: 10px;
      margin-right: 10px;
      transition: all 0.3s;
      ${({ display, }) => display && css`transform:rotate(90deg);`}
    }
  }

  span{
    background-color: ${({ theme, }) => theme.palette.common.black};
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: ${({ theme, }) => theme.palette.common.white};
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

  }
`
export const OrderTotal = styled.div`
  background-color: ${({ theme, }) => theme.palette.common.black};
  color: ${({ theme, }) => theme.palette.common.white};
  border-radius: 0 0 16px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: normal;

  span{
    font-size: 22px;
    font-weight: bold;
  }

`