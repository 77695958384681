import React from "react"
import { useRecoilValue, useRecoilValueLoadable } from "recoil"
import { ordersAtom, sectionSelectAtom, assertStoreSelector } from "state"
import { ShoppingConfirmCartStyle, } from "../Style/ShoppingConfirmCartStyle"
import { useLoadableValueHelper } from "hooks/useLoadableValueHelper"
import { useNavigate, useParams } from "react-router-dom"
import LoadingModal from "components/displays/LoadingModal"
import { OrderState } from "gdu-vq-common/src/api/interfaces/shared/models/Order"
import Modal from "components/displays/Modal"
import { Modals } from "styles/ModalStyle"
import {
    Section, SectionWrapper as SW,
} from "../../getStarted/components/ModalContent/ModalContentStyles"
import { ModalTitle, Hr, } from "../Style/orderSentStyles"
import { usePathsNavigator } from "util/paths"
import OrderCard from "pages/order/ordersByState/OrderCard"

const contents = [
    "Tu pedido se esta preparando. Podes consultar su estado en la pestaña pedidos",
    // eslint-disable-next-line max-len
    <>Cuando el pedido este pronto, <b>podés retirarlo en la sección con el código identificador</b></>,
]

// eslint-disable-next-line max-lines-per-function
const OrderSent = () => {
    const orderId = useParams<"order_sent_id">().order_sent_id
    const selector = useLoadableValueHelper(useRecoilValueLoadable(assertStoreSelector))
    const orders = useRecoilValue(ordersAtom)
    const section = useLoadableValueHelper(useRecoilValueLoadable(sectionSelectAtom))
    const paths = usePathsNavigator()
    const navigate = useNavigate()

    if (section.loading || selector.loading || orders === undefined) { return <LoadingModal /> }
    const order = orders.find(o => o.id === orderId)

    if (!order) {
        throw new Error(`No order with id: ${orderId}`)
    }

    const onClose = () => {
        navigate(paths("section"))
    }

    return (
        <Modal modalIsOpen={true} modalStyle={Modals.screen} closeModal={onClose}>
            <ShoppingConfirmCartStyle.ContainerDiv>
                <ShoppingConfirmCartStyle.WrapperDiv>
                    <ModalTitle>
                        {order.state === OrderState.completed
                            ? "Pedido Completado"
                            : "Pedido Enviado"}
                    </ModalTitle>
                    <Hr />
                    <OrderCard order={order} showSubTotal={false} />
                </ShoppingConfirmCartStyle.WrapperDiv>
                <div>
                    <SW>
                        {contents.map((val, index) => (
                            <Section key={index}>
                                <span>{index + 1}</span>
                                <p>{val}</p>
                            </Section>
                        ))}
                    </SW>
                </div>

            </ShoppingConfirmCartStyle.ContainerDiv>
        </Modal>
    )
}

export default OrderSent