import styled from "styled-components"

export const DivIcon = styled.div`
  border: 2px solid ${({ theme, }) => theme.palette.primary.main};
  width: 29px;
  height: 29px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

`

export const TimeP = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-size: 27px;
  height: 35px;
  font-weight: 600;
  color: ${({ theme, }) => theme.palette.primary.main};
`
export const TextP = styled.p`
  text-align: center;
  line-height: 130%;
  letter-spacing: -0.2px;
  font-size: 14px;
  font-weight: 800;
  color: ${({ theme, }) => theme.palette.primary.main};
`

export const StyledDiv = styled.div`
  align-self: center;
  color: ${({ theme, }) => theme.palette.primary.main};
`

export const OrgDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
