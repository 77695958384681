import React from "react"
import { StoreSection } from "gdu-vq-common/src/api/interfaces/consumer/models/ConsumerStore"
import { sectionImg, } from "components/utils/brandsImg/brandsImage"
import {
    MainDiv,
    ContainerDiv,
    SectionDiv,
    SectionDescDiv,
    StyledImg,
    StyledP,
    ImageWrapper,
    SelectionMark,
    SelectionMarkWrapper
} from "./SectionListStyles"
import { StoreInfo } from "types"
import WaitingTime from "./components/WaitingTime"
import { ReactComponent as TickIcon } from "assets/icons/tick_icon.svg"
import { SectionTextByType } from "gdu-vq-common/src/api/interfaces/shared/models/Section"


type PropsType = {
    onClick: (section: StoreSection) => void,
    store: StoreInfo,
    selectedSection?: StoreSection | null,
}

const Sections = ({ onClick, selectedSection, store, }: PropsType) => {

    return <MainDiv>
        <>
            {store.sections.map((section: StoreSection) => {

                const selected = section.id === selectedSection?.id
                return (
                    <ContainerDiv
                        key={section.id}
                        onClick={() => {
                            onClick(section)
                        }}
                        selected={selected}
                    >
                        <ImageWrapper>
                            <StyledImg
                                src={sectionImg[section.sectionType]}
                            />
                        </ImageWrapper>
                        <SectionDiv>
                            <SectionDescDiv>
                                <StyledP>
                                    {SectionTextByType[section.sectionType]}
                                </StyledP>
                            </SectionDescDiv>
                            <WaitingTime
                                time={section.waitTime}
                                selected={selected}
                            />
                        </SectionDiv>
                        <SelectionMarkWrapper>
                            <SelectionMark
                                selected={selected} >
                                {selected && <TickIcon />}
                            </SelectionMark>
                        </SelectionMarkWrapper>
                    </ContainerDiv>
                )
            })}
        </>
    </MainDiv >
}

export default Sections