import styled from "styled-components"

export const Title = styled.h2`
  font-size: 25px;
  font-weight:600;
  line-height: 131%;
  letter-spacing: -0.02em;
  color: ${({ theme, }) => theme.palette.common.black2};
  text-align: center;
  width: 100%;
`

export const SectionsWrapper = styled.div`
  padding: 85px 0px;
  
`
export const Message = styled.span`
   font-size: 14px; 
`
export const TitleWrapper = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:space-between;
    align-items: center;
    border-bottom: 1px solid ${({ theme, }) => theme.palette.common.grey7};
    padding: 14px 0;
    background: rgba(246, 249, 251, 0.83);
    backdrop-filter: blur(40px);
    width: 100%;
    position: fixed;
    z-index: 5;
`
export const TagsWrapper = styled.div`
    display:flex;
    justify-content:space-between;
    width: 100%;
`
export const BackButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 7px;
    gap: 6px;
    background: ${({ theme, }) => theme.palette.common.grey12};
    border-radius: 6px;
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.02em;
    color: ${({ theme, }) => theme.palette.common.grey13};
`
