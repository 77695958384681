import React from "react"
import { Navigate, Route, } from "react-router-dom"
import { pathsDefinition, usePathsNavigator } from "util/paths"
import Welcome from "pages/getStarted/Welcome"
import GetStarted from "pages/getStarted/GetStarted"
import RootRoutes from "components/utils/GenerateAbsolutePath"
import { getReadyVisit } from "services/session"


const Unprotected = () => {
    const paths = usePathsNavigator()
    const readyVisit = getReadyVisit()
    const initialStep = readyVisit ? 5 : 0

    return <>
        <RootRoutes>
            <Route path={pathsDefinition.root} element={<GetStarted initialStep={initialStep} />} />
            <Route path={pathsDefinition.welcome} element={<Welcome />} />
            <Route path="*" element={<Navigate to={paths("root")} />} />
        </RootRoutes>
    </>
}

export default Unprotected