import React from "react"
import styled from "styled-components"
import ButtonTypes from "./ButtonTypes"
import { ReactComponent as PlusButton} from "assets/icons/plusOutlined.svg"


const StyledAddButton = styled.button`
  padding: 8px 19px;
  color: ${({ theme, }) => theme.palette.common.offWhite};
  border-radius: 8px;
  background-color: ${({ theme, }) => theme.palette.common.white};
  :focus{
    box-shadow: 0px 0px 0px 4px rgba(51, 51, 51, 0.25);
  }
  :hover{
    box-shadow: 0px 0px 0px 4px rgba(51, 51, 51, 0.25);
  }
  outline: none;
`

const AddButton = (props: ButtonTypes) => {
  return (
    <StyledAddButton {...props} style={{ ...props.extraClasses, }}>
      <PlusButton />
    </StyledAddButton>
  )
}

export default AddButton
