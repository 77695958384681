import styled from "styled-components"

export const ProductImgDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    max-height: 60px;
    border-radius: 8px;
    margin-right: 11px;
    margin-bottom: 10px;
    overflow: hidden;
    border: 1px solid ${({ theme, }) => theme.palette.common.grey8};

    img{
        max-height: 100%;
        width: auto;
    }
`

export const ProductHeader = styled.div`
    display: flex;
    position:relative;

    :after{
        content: "";
        position: absolute;
        bottom:0;
        width:calc(100% + 39px);
        height:1px;
        background-color:${({ theme, }) => theme.palette.common.grey8};
        left:-23px;
    }
`

export const ProductInfoDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 30px;
`

export const ProductDetailP = styled.p`
    font-size: 13px;
    font-weight: 600;
    color:${({ theme, }) => theme.palette.common.grey9};
`

export const DescriptionP = styled.p`
    text-align: left;
    width: calc(100% - 20px);
    font-size: 16px;
    line-height: 130%;
    font-weight: 600;
    margin-bottom:10px;
`

export const ProductUnit = styled.span`
    text-transform: lowercase;
`