import React from "react"
import {
    ConsumerOrder,
} from "gdu-vq-common/src/api/interfaces/consumer/models/ConsumerOrder"
import {
    OrderState as OrderStateEnum,
} from "gdu-vq-common/src/api/interfaces/shared/models/Order"
import Order from "./Order"
import OrderState from "./OrderState"
import { StoreSection } from "gdu-vq-common/src/api/interfaces/consumer/models/ConsumerStore"
import { Content } from "./styles/OrderByStateStyle"

type Props = {
    orders: ConsumerOrder[],
    state: OrderStateEnum,
    sections: StoreSection[],
}

const OrderByState = ({ orders, state, sections,  }: Props) => (<>
    <OrderState amount={orders.length} state={state}/>
    <Content>
        {orders.map((order) => (
            <Order
                key={order.id}
                order={order}
                section={sections.find((section) => section.id === order.seccionId)}
            />
        ))
        }
    </Content>
</>)

export default OrderByState
