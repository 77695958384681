import React, { ReactChild } from "react"

type Props = {
    children: ReactChild | ReactChild[],
    extraClasses?: React.CSSProperties | undefined,
    withHeaderNavigation?: boolean,
}


const Layout = ({ children, extraClasses, withHeaderNavigation, }: Props) => (

    <div style={{ height: "100%", ...extraClasses, }}>
        {children}
    </div >
)

export default Layout
