import startedBrand from "assets/icons/disco/startedBrand.svg"
import locationRed from "assets/icons/disco/locationRed.svg"
import locationBlack from "assets/icons/locationBlack.svg"
import discoLogo from "assets/icons/disco/discoLogo.svg"

export const discoImg = {
    locationRed,
    discoLogo,
    startedBrand,
    locationBlack,
}

export const devotoImg = {
    locationRed,
    discoLogo,
    startedBrand,
    locationBlack,
}

export const geantImg = {
    locationRed,
    discoLogo,
    startedBrand,
    locationBlack,
}