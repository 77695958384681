import styled from "styled-components"

export const CartEmptyContainer = styled.div`
  padding-top: 220px;
  text-align: center;
  font-size: 14px;
`
export const CartEmptyText = styled.p`
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.3px;
`

export const ItemsContainer = styled.div`
  display: block;
  scroll-behavior: auto;
  max-height: 80%;
  margin-top: 8px;
  width: 100%;
  padding: 0px 2px 220px 2px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`
export const DivGr = styled.div`
  color: ${({ theme, }) => theme.palette.common.grey9};
  font-size: 14px;
`

export const CartItemDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme, }) => theme.palette.common.white};
  border-radius: ${({ theme, }) => theme.borderRadius};
  box-shadow: 0px 0px 1px 2px ${({ theme, }) => theme.palette.common.grey12};
  margin-top: 10px;
  position: relative;
`
export const ProductAmountWrapper = styled.div`
  height: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: start;
  margin-top: 7px;
`

export const ProductImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: ${({ theme, }) => theme.palette.common.white};
  margin-left: 8px;
  margin-right: 10px;
  border: 1px solid ${({ theme, }) => theme.palette.common.grey8};
  box-shadow: 0px 7px 6px -6px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
`

export const ContainerDiv = styled.div`
  padding: 0px 8px 8px ;
  width: 100%;
`

export const DescriptionP = styled.p`
  font-weight: 600;
  font-size: 15px;
  text-align: start;
  max-height: 65px;
  line-height: 140%;
  letter-spacing: -0.02em;
  text-transform: lowercase;
  max-width: 60%;
  color: ${({ theme, }) => theme.palette.common.black};
  ::first-letter {
    text-transform: uppercase;
    font-weight: bold;
  }
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
`

export const FlexDirection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  right: 8px;
  top: 7px;
`
export const PriceP = styled.p`
  font-size: 22px;
  color: black;
  text-align: left;
  align-self: center;
  line-height: 120%;
  letter-spacing: -0.3px;
  font-weight: 600;
  display: flex;

  p {
    font-size: 15px;
    color: ${({ theme, }) => theme.palette.common.grey7};
    position: relative;
    top: 2px;
  }
`
export const PriceText = styled.p`
  font-size: 9px;
  align-self: center;
  margin-left: 4px;
`
export const ButtonsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
`

export const defaultButton: React.CSSProperties = {
  width: "calc(50% - 4px)",
  padding: "6px",
  borderRadius: "8px",
  fontSize: "12px",
  textTransform: "capitalize",
}


export const DeleteButton = styled.button`
  ${{ ...defaultButton, }}
  background-color: ${({ theme, }) => theme.palette.common.white};
  color: ${({ theme, }) => theme.palette.common.red};
  border: 1px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 140%;
  border: 1px solid rgba(235, 75, 75, 0.5);
  height: 34px;

  p {
    font-weight: 800;
    font-size: 15px;
    margin-left: 5px;
  }
`

export const EditButton = styled.button`
  ${{ ...defaultButton, }}
  background-color: ${({ theme, }) => theme.palette.common.white};
  color: ${({ theme, }) => theme.palette.primary.main};
  border: 1px solid green;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 140%;
  border: 1px solid rgba(0, 105, 55, 0.5);
  border-radius: 8px;
  height: 34px;

  p {
    font-weight: 800;
    font-size: 15px;
    margin-left: 5px;
  }
`

export const ContainerConfirmModal = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  padding: 22px 15px;
`

export const ConfirmAlert = styled.p`
  font-family: 'Silka';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  margin-top: -21px;
  margin-bottom: 13px;
  color: ${({ theme, }) => theme.palette.common.black};
  flex: none;
  order: 0;
  flex-grow: 0;
`

export const ConfirmMsg = styled.p`
  text-align: center;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.3px;
  margin-bottom: 36px;
  font-family: 'Silka';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0,2em;
  color: ${({ theme, }) => theme.palette.common.grey11};
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const confirmDefaultButton: React.CSSProperties = {
  width: "calc(102% - 2px)",
  fontSize: "12px",
  padding: "8px 4px",
  borderRadius: "8px",
  lineHeight: "140%",
  letterSpacing: "-0.3px",
}

export const ConfirmDeleteButton = styled.button`
  ${{ ...confirmDefaultButton, }}
  background-color: ${({ theme, }) => theme.palette.common.red2};
  color: ${({ theme, }) => theme.palette.common.offWhite};
  border: 1px solid transparent;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 0px 10px 10px;

`

export const ConfirmBackButton = styled.button`
  ${{ ...confirmDefaultButton, }}
  color: ${({ theme, }) => theme.palette.primary.main};
  border: 1px solid ${({ theme, }) => theme.palette.primary.main};
  font-weight: 600;
  font-size: 16px;
  padding: 10px 0px 10px 10px;
`

export const SectionImg = styled.img`
  border-radius: 8px;
  width: 40px;
  height: 40px;
  padding: 6px;
  filter: invert();
`

export const TextContent = styled.div`
  display: flex;
  margin-left: 12px;
  flex-direction: column;
`

export const Location = styled.div`
  display: flex;
  align-items: center;
`

export const SectionTitle = styled.span`
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
`

export const Brand = styled.span`
  font-size: 8px;
  text-transform: uppercase;
  margin-left: 5px;
  display: flex;
  align-items: center;
`

export const Message = styled.span`
  font-size: 14px;
`

export const DivDivider = styled.div`
  width: 50px;
  height: 1px;
  background-color: ${({ theme, }) => theme.palette.common.grey8};
`
