import React, { useEffect } from "react"
import Layout from "components/layouts/Layout"
import { useSpring, useTransform, useViewportScroll } from "framer-motion"
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue } from "recoil"
import { selectedSectionIdAtom, assertStoreSelector, refreshStorePending } from "state"
import SectionChange from "./sectionWrapper/SectionChange"
import {
    SubTitle, Title, TitleWrapper, SectionWrapper,
} from "./sectionWrapper/SectionChangeStyles"
import SectionSelect from "./sectionWrapper/SectionSelect"
import NavigationBar from "components/displays/NavigationBar"


const Section = () => {
    const sectionId = useRecoilValue(selectedSectionIdAtom)
    const [refreshStore, setRefreshStore,] = useRecoilState(refreshStorePending)
    const refresh = useRecoilRefresher_UNSTABLE(assertStoreSelector)
    const { scrollY, } = useViewportScroll()

    const yRange = useTransform(scrollY, [0, 110,], [1, 0,])
    const opacity = useSpring(yRange, { stiffness: 400, damping: 40, })


    useEffect(() => {
        if (refreshStore) {
            setRefreshStore(false)
            refresh()
        }
    }, [refreshStore,])

    return <Layout withHeaderNavigation extraClasses={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
    }}>
        <TitleWrapper style={{ opacity, }}>
            <Title>Secciones disponibles</Title>
            <SubTitle>
                Elegi la sección en la que querés comprar.
                <br />
                Para cambiar de sección tu carrito debe estar vacío
            </SubTitle>
        </TitleWrapper>
        <SectionWrapper>
            {sectionId
                ? <SectionChange />
                : <SectionSelect />
            }
        </SectionWrapper>
        <NavigationBar />
    </Layout>

}

export default Section
