import React, { useEffect } from "react"
import { Modals } from "styles/ModalStyle"
import {
  Title,
  Content,
  ContentWrapper,
  Footer,
  Hr,
} from "./styles/FinishedOrderModalStyles"
import {
  Section, SectionWrapper as SW,
} from "../../pages/getStarted/components/ModalContent/ModalContentStyles"

import { ConsumerOrder } from "gdu-vq-common/src/api/interfaces/consumer/models/ConsumerOrder"
import { useLoadableValueHelper } from "hooks/useLoadableValueHelper"
import { useRecoilValueLoadable } from "recoil"
import { assertStoreSelector } from "state"
import LoadingModal from "./LoadingModal"
import Modal from "components/displays/Modal"
import { useTrackEvent } from "hooks/useTrackEvent"
import OrderCard from "pages/order/ordersByState/OrderCard"
import { OrderState } from "gdu-vq-common/src/api/interfaces/shared/models/Order"
import Feedback from "./Feedback"

type PropsType = {
  isOpen?: boolean,
  onClose?: () => void,
  order: ConsumerOrder,
}

const contents = [
  "Tu pedido está listo para retirar. Podrás identificarlo con el código",
]

// eslint-disable-next-line max-lines-per-function
const FinishedOrderModal = ({ order, isOpen = true, onClose, }: PropsType) => {
  const responseStore = useLoadableValueHelper(useRecoilValueLoadable(assertStoreSelector))
  const { completeOrderNotification, feedback, } = useTrackEvent()

  useEffect(() => {
    if (!responseStore.loading) {
      const missingProducts = order.orderLine.filter(ol => ol.missing)
      completeOrderNotification(order.id, missingProducts.length)
    }
  }, [responseStore.loading,])
  if (responseStore.loading) {
    return <LoadingModal />
  }

  return <Modal
    modalIsOpen={isOpen}
    modalStyle={Modals.screenNoPadding}
    closeModal={onClose}
  >
    <ContentWrapper >
      <Content>
        <Title>Pedido completado</Title>
        <Hr />
        <OrderCard
          order={order}
          showSubTotal={order.state === OrderState.completed}
        />
        <Footer >
          <SW>
            {contents.map((val, index) => (
              <Section key={index}>
                <p>{val}</p>
              </Section>
            ))}
          </SW>
        </Footer>
        <Feedback
          sendComment={(comment: string,) => feedback(comment, order.id, order.seccionType)}
        />
      </Content>
    </ContentWrapper>
  </Modal >
}

export default FinishedOrderModal