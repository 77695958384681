import React from "react"
import { Wrapper } from "./WaitingTimeStyles"

type PropsType = {
    time: number,
    selected?: boolean,
}

const WaitingTime = ({ time, selected, }: PropsType) => {

    return <Wrapper selected={selected}>
            {time} minutos de espera
       </Wrapper >
}

export default WaitingTime