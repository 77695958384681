import mixpanel from "mixpanel-browser"
import { EVENTS, LogSessionDataType } from "types/TrackingEventType"
import { EnvHelper } from "./EnvHelper"
import { getStoreBrand } from "./paths"

const initMixpanel = () => {
    if (EnvHelper.mixpanelId) {
        mixpanel.init(EnvHelper.mixpanelId)
    }
}

export const track: typeof mixpanel.track = (eventName, properties) => {
    if (EnvHelper.mixpanelId) {
        mixpanel.track(eventName, properties)
    }
}

const viewWelcomePage = () => {
    const { brand, storeSlug, } = getStoreBrand()!
    track(EVENTS.VIEW_WELCOME_PAGE, {
        brand,
        sucursalSlug: storeSlug,
    })
}

const nonUserLoyal = (data: LogSessionDataType) => {
    const { brand, storeSlug, } = getStoreBrand()!
    track(EVENTS.NON_USER_LOYAL, {
        brand,
        sucursalSlug: storeSlug,
        ...data,
    })
}

const login = (data: LogSessionDataType) => {
    const { brand, storeSlug, } = getStoreBrand()!
    track(EVENTS.LOGIN, {
        brand,
        sucursalSlug: storeSlug,
        ...data,
    })
}

const logout = () => {
    const { brand, storeSlug, } = getStoreBrand()!
    track(EVENTS.LOGOUT, {
        brand,
        sucursalSlug: storeSlug,
    })
}

const noAfiliated = () => {
    const { brand, storeSlug, } = getStoreBrand()!
    track(EVENTS.NO_AFILIATED_FAQ, {
        brand,
        sucursalSlug: storeSlug,
    })
}

const skipTutorial = () => {
    const { brand, storeSlug, } = getStoreBrand()!
    track(EVENTS.SKIP_TUTORIAL, {
        brand,
        sucursalSlug: storeSlug,
    })
}

const identifyUser = (ci: string) => {
    if (EnvHelper.mixpanelId) {
        mixpanel.identify(ci)
        mixpanel.people.set({ name: ci, })
    }
}

export const trackEvent = {
    initMixpanel,
    identifyUser,
    skipTutorial,
    noAfiliated,
    logout,
    login,
    nonUserLoyal,
    viewWelcomePage,
}