import React from "react"
import { ReactComponent as ClearFilter } from "assets/icons/clearFilter.svg"
import { ReactComponent as SearchIcon } from "assets/icons/searchIcon.svg"
import { ReactComponent as ArrowLeft } from "assets/icons/arrow_left.svg"
import {
    BackButton,
    SearchInput,
    SearchWrapper,
    TagsWrapper,
    Title,
    TitleWrapper,
    WaitingTimeWrapper,
    Wrapper
} from "./styles/ProductsHeaderStyles"
import { DefaultTheme, withTheme } from "styled-components"

type PropsType = {
    sectionName: string,
    waitTime: number,
    productFilter: string,
    handleFilterProductList: (value: string) => void,
    theme: DefaultTheme,
    changeSection: () => void,
}

const ProductsHeader = ({
    sectionName,
    waitTime,
    productFilter,
    handleFilterProductList,
    theme,
    changeSection,
}: PropsType) => {
    return (<Wrapper>
        <TitleWrapper>
            <Title > <span>{sectionName}</span></Title>
            <TagsWrapper>
                <BackButton onClick={changeSection} >
                    <ArrowLeft width={13} height={11} />
                    Cambiar Sección
                </BackButton>
                <WaitingTimeWrapper>{waitTime} min de espera</WaitingTimeWrapper>
            </TagsWrapper>
        </TitleWrapper>
        <SearchWrapper>
            <SearchIcon fill={theme.palette.common.grey7} />
            <SearchInput
                type="text"
                value={productFilter}
                placeholder="Buscar un producto"
                onChange={(event) => handleFilterProductList(event.target.value)}
            />
            {productFilter && <ClearFilter stroke={theme.palette.common.grey7}
                onClick={() => handleFilterProductList("")}
            />}
        </SearchWrapper>
    </Wrapper>)
}


export default withTheme(ProductsHeader)