import React from "react"
import InlineNotification, { NotificationType } from "components/displays/InlineNotification"
import { defaultProductImgGreen } from "components/utils/brandsImg/brandsImage"
import {
    SectionTextByType, SectionType
} from "gdu-vq-common/src/api/interfaces/shared/models/Section"
import {
    FirstHeader,
    FirstText,
    FirstWrapper,
    InfoWrapper,
    SecondAndThirdWrapper,
    SecondText,
    SecondWrapper,
    ThirdHeader,
    ThirdText,
    ThirdWrapper,
    ThreeInfoWrapper,
    RedButtonWrapper,
    RedButtonText,
} from "styles/ThreeInfoDisplayStyles"
import { ConsumerOrder } from "gdu-vq-common/src/api/interfaces/consumer/models/ConsumerOrder"
import {
    calculatePrice, productReadablePrice
} from "gdu-vq-common/src/api/interfaces/shared/helpers/ProductUtil"
import { OrderState } from "gdu-vq-common/src/api/interfaces/shared/models/Order"
import PreparationTimeCountdown from "components/displays/PreparationTimeCountdown"


const OrderCard = (props: {
    order: ConsumerOrder,
    showSubTotal: boolean,
    cancelOrder?: () => void,
}) => {
    const { order, showSubTotal, cancelOrder, } = props

    const renderSection = (sectionType: SectionType) => {
        return (<>
            <img src={defaultProductImgGreen[sectionType]} className="sectionImg" />
            <SecondText>{SectionTextByType[sectionType]}</SecondText>
        </>)
    }

    const activeProducts = order.orderLine.filter(ol => !ol.missing)
    const isThereMissingProducts = activeProducts.length < order.orderLine.length
    const subtotal = productReadablePrice(
        activeProducts.reduce(
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            (accum, current) => accum + calculatePrice(
                current.product!.price,
                current.product!.increments,
                current.quantity,
                current.product!.unit,
            ), 0)
    )

    return <ThreeInfoWrapper>
        <FirstWrapper>
            <FirstText>Tu pedido tendrá este código impreso:</FirstText>
            <FirstHeader>{order.alternativeId}</FirstHeader>
            {isThereMissingProducts && <InfoWrapper>
                <InlineNotification notificationType={NotificationType.information}>
                    PRODUCTOS FALTANTES
                </InlineNotification>
            </InfoWrapper>}
            {cancelOrder
                && <RedButtonWrapper>
                    <RedButtonText onClick={(e) => { e.stopPropagation(); cancelOrder() }}>
                        Cancelar Pedido
                    </RedButtonText>
                </RedButtonWrapper>
            }
        </FirstWrapper>
        <SecondAndThirdWrapper>
            <SecondWrapper>
                {order.seccionType ? renderSection(order.seccionType as SectionType)
                    : <SecondText>Sección no encontrada</SecondText>}
            </SecondWrapper>
            <ThirdWrapper>
                {showSubTotal
                    ? <>
                        <ThirdHeader>{subtotal}</ThirdHeader>
                        <ThirdText>Total estimado</ThirdText>
                    </>
                    : <>
                        <PreparationTimeCountdown
                            isoDate={order.estimatedDate}
                            completed={order.state === OrderState.completed}
                        />
                    </>
                }
            </ThirdWrapper>
        </SecondAndThirdWrapper>
    </ThreeInfoWrapper>
}

export default OrderCard