import React from "react"
import { BrowserRouter, Route, Routes, } from "react-router-dom"
import Protected from "./Protected"
import useSession from "hooks/useSession"
import Unprotected from "./Unprotected"
import ErrorModal from "components/displays/ErrorModal"
import { initialPath } from "util/paths"
import LoadingModal from "components/displays/LoadingModal"
import InvalidStoreModal from "components/displays/InvalidStoreModal"
import { useLoadableValueHelper } from "hooks/useLoadableValueHelper"
import { useRecoilValueLoadable } from "recoil"
import { storeSelector } from "state"

const AppRouter = () => {
    const { isLogged, } = useSession()
    const responseValidStore = useLoadableValueHelper(useRecoilValueLoadable(storeSelector))
    if (responseValidStore.error) { throw new Error("Invalid Store") }

    if (responseValidStore.loading) {
        return <LoadingModal isOpen={true} />
    }

    if (!responseValidStore.data) {
        return <InvalidStoreModal />
    }

    const component = isLogged ? <Protected /> : <Unprotected />

    return (
        <BrowserRouter>
            <Routes>
                <Route path={initialPath} element={component} />
                <Route path="*" element={<ErrorModal />} />
            </Routes>
        </BrowserRouter>
    )
}

export default AppRouter
