import styled from "styled-components"
import { MODALS_ZINDEX } from "styles/GlobalStyles"

export const Container = styled.div`
  position: absolute;
  width:100%;
  height:100%;
  top:0;
  left:0;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: ${MODALS_ZINDEX};
`

export const ContentWrapper = styled.div`
  display: flex;
  height: calc(100% - 60px);
  justify-content: center;
  align-items: center;
  
`

export const Content = styled.div`
  width:auto;
  min-width: 60vw;
  height:auto;
  max-width: 225px;
  background-color: rgba(255, 46, 46, 0.7);
  display:flex;
  justify-content:center;
  flex-direction:column;
  align-items:center;
  border-radius:24px;
  color: white;
  font-size:12px;
  backdrop-filter: blur(8px);
  padding: 32px 20px;
`

export const Title = styled.p`
  font-size:16px;
  font-weight:600;
  margin-top:8px;
`

export const SubTitle = styled.p`
  font-weight:400;
  margin-top:4px;
  text-align: center;
`

export const Icon = styled.img`
  width:31px;
  height:31px;
`

export const BackToStart = styled.a`
  padding-top: 15px;
  width: 85%;
  text-align: center;
  text-decoration: underline;
`