import React from "react"
import { Modals } from "styles/OldModalStyle"
import ModalComp from "components/displays/ModalComp"
import {
  ContainerConfirmModal,
  ConfirmMsg,
  ButtonsContainer,
  ConfirmBackButton,
  ConfirmDeleteButton,
  Warning,
} from "./styles/ConfirmOrderCancelStyle"
import InlineNotification, { NotificationType } from "components/displays/InlineNotification"


const ConfirmOrderCancel = (props: {
  openModal: boolean,
  closeModal: () => void,
  confirmCancel: () => void,
}) => {
  return (
    <ModalComp
      modalIsOpen={props.openModal}
      modalStyle={Modals.confirm}
      closeModal={props.closeModal}
    >
      <ContainerConfirmModal>
        <ConfirmMsg>
          ¿Estás seguro de que deseas cancelar el pedido?
          <Warning>
            <InlineNotification notificationType={NotificationType.error}>
              los productos serán descartados
            </InlineNotification>
          </Warning>
        </ConfirmMsg>
        <ButtonsContainer>
          <ConfirmBackButton
            onClick={props.closeModal}
          >
            Volver atrás
          </ConfirmBackButton>
          <ConfirmDeleteButton
            onClick={() => {
              props.confirmCancel()
              props.closeModal()
            }}
          >
            Cancelar pedido
          </ConfirmDeleteButton>
        </ButtonsContainer>
      </ContainerConfirmModal>
    </ModalComp>
  )
}

export default ConfirmOrderCancel