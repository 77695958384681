import styled from "styled-components"
export const Wrapper = styled.div`
    padding: 15px 16px 15px 16px;
    width: 100%;
    background: rgba(246, 249, 251, 0.83);
    position:absolute;
    top:0;
    left: 0;
    z-index: 1;
    backdrop-filter: blur(40px);
    font-family: Silka;
`

export const TitleWrapper = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:space-between;
    align-items: center;
`
export const Title = styled.div`
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    gap: 8px;
    width: fit-content;
    margin-bottom: 15px;

    span{
        text-transform: capitalize;
    }
`

export const TagsWrapper = styled.div`
    display:flex;
    justify-content:space-between;
    width: 100%;
`
export const BackButton = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 7px;
    gap: 6px;
    background: ${({ theme, }) => theme.palette.common.grey12};
    border-radius: 6px;
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.02em;
    color: ${({ theme, }) => theme.palette.common.grey13};
`


export const WaitingTimeWrapper = styled.div`
    display: flex;
    width: fit-content;
    background-color:green;
    border-radius:8px;
    padding:0 18px;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    height:1.5rem;
    background-color:${({ theme, }) => theme.palette.common.green1};
    color:${({ theme, }) => theme.palette.primary.main};
`

export const SearchWrapper = styled.div`
    margin-top: 8px;
    display: flex;
    background-color: ${({ theme, }) => theme.palette.common.white};
    border-radius: 14px;
    padding: 0 16px;
    align-items: center;
    border: 1px solid ${({ theme, }) => theme.palette.common.grey1};
    box-shadow: 0px 15px 16px -13px rgba(0, 0, 0, 0.09);
`
export const SearchInput = styled.input`
    width: 100%;
    padding: 8px;
    background-color: ${({ theme, }) => theme.palette.common.white};
    border: 1px solid transparent;
    font-size: 16px;
    font-weight: 100;
    font-family: Silka;
    color:${({ theme, }) => theme.palette.common.black};

    :focus{
        outline: none;
    }
    &::placeholder {
        color:${({ theme, }) => theme.palette.common.grey7};
        font-weight: 100;
        font-family: Silka;
    }
`
