import styled, { css } from "styled-components"

export const ProductDetailDiv = styled.div`
    height: 80%;
    width: 100%;
`

export const PriceWrapper = styled.div`
    display:flex;
    flex-direction:column;
    background-color: ${({ theme, }) => theme.palette.primary.main};
    border-radius: ${({ theme, }) => theme.borderRadius};
    overflow: hidden;
    justify-content: center;
    text-align: center;
    margin-top:10px;
    color: ${({ theme, }) => theme.palette.common.white};
`
export const Subtotal = styled.div`
    font-weight: 600;
    font-size: 26px;
    line-height: 140%;
    color: ${({ theme, }) => theme.palette.common.white};

    &::first-letter{
        color: rgba(255, 255, 255, 0.45);
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
    }

`
export const EstimatedWarning = styled.p`
    margin-top: -5px;
    color: rgba(255, 255, 255, 0.45);
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
`

export const Amount = styled.div`
    display:flex;
    align-items: flex-end;
`



export const KeypadQtyDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 7px;
    background-color: ${({ theme, }) => theme.palette.common.green3};
    border-top: 1px solid rgba(242,242,242,0.25);
`

export const QtyP = styled.p`
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 105%;
    letter-spacing: -0.35px;
`
export const UnitP = styled.p`
    text-align: center;
    margin-bottom: -0px;
    color: rgba(255, 255, 255, 0.45);
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    margin-left: 1px;
    margin-bottom: -1px;
    text-transform: lowercase;
    letter-spacing: -0.02em;

`

export const InfoP = styled.div`
    margin-top: 10px;
    font-size: 16px;
    margin-bottom: 7px;
    color: ${({ theme, }) => theme.palette.common.grey9};
`

export const CustomizedDiv = styled.div`
    position: relative;
    margin-top:16px;
    width: 100%;
    left: 0;
    padding: 0;
`
export const DefaultButton = css`
    width: calc(50% - 4px);
    font-size: 18px;
    padding: 16px;
    border-radius: ${({ theme, }) => theme.borderRadius};
    line-height: 140%;
    letter-spacing: -0.3px;
`