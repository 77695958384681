import { Brands } from "gdu-vq-common/src/api/interfaces/shared/models/Brand"
import { generatePath, matchPath, Params, useParams } from "react-router-dom"

type RemovePrefix<T extends string> =
    T extends `:${infer R}`
    ? `:${R}` : (T extends `${string}/:${infer R}` ? `:${R}` : T)


type ExtractParams<T extends string> =
    RemovePrefix<T> extends `:${infer Param}/${infer Sufix}`
    ? Param | ExtractParams<`/${Sufix}`>
    : (
        RemovePrefix<T> extends `:${infer Param}` ? Param : never
    )

export const initialPath = "/:brand/:storeSlug/*"

const basePath = "/:brand/:storeSlug"

export const pathsDefinition = {
    root: `${basePath}`,
    login: `${basePath}/login`,
    logout: `${basePath}/logout`,
    welcome: `${basePath}/welcome`,
    orders: `${basePath}/orders`,
    section: `${basePath}/section`,
    products: `${basePath}/products`,
    shoppingCart: `${basePath}/shoppingCart`,
    productDetail: `${basePath}/products/:product_id`,
    shoppingItemDetail: `${basePath}/shoppingCart/:product_id`,
    orderSent: `${basePath}/orderSent/:order_sent_id`,
    notFound: `${basePath}/*`,
} as const

const generatePaths = (params: Params) =>
    <T extends keyof typeof pathsDefinition>(
        key: T, extraParams?: Partial<Params<ExtractParams<(typeof pathsDefinition)[T]>>>
    ) =>
        generatePath(pathsDefinition[key], { ...params, ...extraParams, })

export const getStoreBrand = () =>
    matchPath(`${pathsDefinition.root}/*`, window.location.pathname)!.params


export const usePathsNavigator = () => {
    const urlParams = useParams()
    return generatePaths(urlParams)
}

export const useBrand = () => {
    const { brand, storeSlug, } = useParams()
    return {
        brand: brand as Brands,
        storeSlug,
    }
}
