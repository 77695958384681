import { DateTime } from "luxon"
import React, { useEffect, useState } from "react"
import { PreparationTime } from "./PreparationTime"


const PreparationTimeCountdownOrders = (props: { isoDate: string, completed: boolean, }) => {
    const calculateDiff = () => {
        const date = new Date(props.isoDate)
        const now = DateTime.now()
        const dateTime = DateTime.fromJSDate(date)
        const time = dateTime.diff(now, "minutes").toObject()

        if (Math.trunc(time.minutes!) > 0) {
            return Math.trunc(time.minutes!)
        } else {
            return 1
        }
    }
    const [estimatedTime, setEstimatedTime,] = useState(calculateDiff())

    useEffect(() => {
        const interval = setInterval(() => {
            setEstimatedTime(calculateDiff())
        }, 1000)
        return () => clearInterval(interval)
    }, [])

    return (
        <PreparationTime
            time={estimatedTime}
            completed={props.completed} />
    )
}

export default PreparationTimeCountdownOrders