import React from "react"
import { Container, Item } from "../styles/ActivePageStyles"

interface Props {
    items: number[];
    active: number;
}

const ActivePage = (props: Props) => {
    return (
        <Container layout>
            {
                props.items.map((it, i) => {
                    return <Item
                        key={`item${i}`}
                        layout
                        active={props.active === i}
                         />
                })
            }
        </Container>
    )
}

export default ActivePage