/* eslint-disable max-lines-per-function */
import React, { useState } from "react"
import { StoreSectionProducts } from
    "gdu-vq-common/src/api/interfaces/consumer/models/ConsumerStore"
import { useNavigate } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { productsSelector, sectionSelectAtom, assertStoreSelector } from "state"
import useCart from "hooks/useCart"
import {
    calculatePrice,
    productReadablePriceCarrito
} from "gdu-vq-common/src/api/interfaces/shared/helpers/ProductUtil"
import { Modals } from "styles/ModalStyle"
import { Cart, CartItem } from "types"
import { OrderProducts } from "api/ApiClient"
import Layout from "components/layouts/Layout"
import { PageTitle } from "components/displays/Elements"
import ShoppingCartItem from "./ShoppingCartItem"
import FooterCartItems from "./FooterCartItems"
import ConfirmOrder from "../confirmCart/ConfirmOrder"
import {
    CartEmptyContainer,
    CartEmptyText,
    ItemsContainer,
    SectionImg,
    TextContent,
    Location,
    SectionTitle,
    Brand,
    Message,
} from "../Style/ShoppingShowCartStyle"
import LoadingModal from "components/displays/LoadingModal"
import { useToaster } from "components/providers/ToastProvider"
import { useBrand, usePathsNavigator } from "util/paths"
import { sectionImg } from "components/utils/brandsImg/brandsImage"
import location from "assets/icons/location.svg"
import { SectionType } from "gdu-vq-common/src/api/interfaces/shared/models/Section"
import Modal from "components/displays/Modal"
import NavigationBar from "components/displays/NavigationBar"
import { CartItemsWrapper } from "../Style/ShoppingCartStyle"
import { useTrackEvent } from "hooks/useTrackEvent"


const ContentToast = (props: {
    imgSrc: string,
    sectionType: SectionType,
    brand: string,
    description: string,
    message: string,
}) => {
    return (
        <>
            <SectionImg src={props.imgSrc} />
            <TextContent>
                <Location>
                    <SectionTitle>{props.sectionType} |</SectionTitle>
                    <Brand>
                        <img src={location} />
                        {props.brand} {props.description}
                    </Brand>
                </Location>
                <Message>{props.message}</Message>
            </TextContent>
        </>
    )
}

const renderEmtpyCart = () => {
    return (
        <CartEmptyContainer>
            <CartEmptyText>No hay productos en tu carrito.</CartEmptyText>
            <CartEmptyText>¡Agrega para realizar tu pedido!</CartEmptyText>
        </CartEmptyContainer>
    )
}

const createOrder = async (cart: Cart, sectionId: string) => {
    const r = await OrderProducts.createOrder("POST", {
        seccionId: sectionId!,
        orderLine: cart.cartItems,
    })
    if (r.ok) {
        return r.content
    } else {
        throw new Error("Creating order error")
    }
}

const ShoppingCartLines = (props: {
    goProductDetail: (product: StoreSectionProducts) => void,
}) => {
    const navigate = useNavigate()
    const paths = usePathsNavigator()
    const brand = useBrand().brand
    const store = useRecoilValue(assertStoreSelector)!
    const section = useRecoilValue(sectionSelectAtom)!
    const [confirmOrder, setConfirmOrder,] = useState(false)
    const [loading, setLoading,] = useState(false)
    const loadedProducts = useRecoilValue(productsSelector)
    const dictionaryProducts = loadedProducts.productsById
    const { cart, clearCart, } = useCart()
    const toaster = useToaster()
    const modalConfirmOrder = (state: boolean) => () => setConfirmOrder(state)
    const { sendOrder, } = useTrackEvent()


    const calculateTotalPrice = () => {
        return cart.cartItems.reduce(
            (accum, current) =>
                accum
                + calculatePrice(
                    dictionaryProducts![current.productId].price,
                    dictionaryProducts![current.productId].increments,
                    current.quantity,
                    dictionaryProducts![current.productId].unit
                ),
            0
        )
    }

    const handleOnCreateRequest = async () => {
        modalConfirmOrder(false)
        setLoading(true)
        try {
            if (!cart.sectionId) {
                throw new Error("Cart is null")
            }
            const oc = await createOrder(cart, section.id)
            const totalPrice = calculateTotalPrice()
            sendOrder(oc.id, cart.cartItems.length, totalPrice)
            navigate(paths("orderSent", { order_sent_id: oc.id, }), { replace: true, })
            clearCart(section.id)
            toaster.toast({
                title: "Success",
                content: (
                    <ContentToast
                        imgSrc={sectionImg[section.sectionType]}
                        sectionType={section.sectionType}
                        brand={brand}
                        description={store.description}
                        message="Tu pedido se encuentra en espera."
                    />
                ),
            })
        } catch (e) {
            setConfirmOrder(false)
            toaster.toast({
                title: "Error",
                content: (
                    <ContentToast
                        imgSrc={sectionImg[section.sectionType]}
                        sectionType={section.sectionType}
                        brand={brand}
                        description={store.description}
                        message="Ha ocurrido un error creando la orden, vuelve a intentarlo"
                    />
                ),
            })
        }
        setLoading(false)
    }

    const handleTotalAmountCarrito = () => {
        return productReadablePriceCarrito(
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            calculateTotalPrice()
        )
    }

    if (loading) {
        return <LoadingModal isOpen={true} />
    } else {
        return (
            <Layout
                extraClasses={{ fontFamily: "Silka", }}
            >
                <PageTitle>Carrito</PageTitle>
                <CartItemsWrapper>
                    {cart.cartItems.length > 0 && dictionaryProducts ? (
                        <ItemsContainer>
                            {cart.cartItems?.map((ic: CartItem) => (
                                <ShoppingCartItem
                                    key={ic.productId}
                                    cartItem={ic}
                                    productItem={dictionaryProducts![ic.productId]}
                                    onClick={() =>
                                        props.goProductDetail(dictionaryProducts![ic.productId])
                                    }
                                />
                            ))}{" "}
                        </ItemsContainer>
                    ) : (
                        renderEmtpyCart()
                    )}
                </CartItemsWrapper>
                <FooterCartItems
                    disabled={!cart.cartItems.length}
                    handleTotalAmountCarrito={handleTotalAmountCarrito}
                    modalConfirmOrder={modalConfirmOrder(true)}
                />
                <>
                    {confirmOrder && (
                        <Modal
                            modalStyle={Modals.confirm}
                            closeModal={modalConfirmOrder(false)}
                            modalIsOpen={confirmOrder}
                        >
                            <ConfirmOrder
                                cancel={modalConfirmOrder}
                                confirm={handleOnCreateRequest} />
                        </Modal>
                    )}
                </>
                <NavigationBar />
            </Layout>
        )
    }
}

export default ShoppingCartLines
