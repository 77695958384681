import personWhite from "assets/icons/started/personWhite.svg"
import pointerSelect from "assets/icons/started/pointerSelect.svg"
import cart from "assets/icons/started/cart.svg"
import watch from "assets/icons/started/watch.svg"
import discoTicket from "assets/icons/disco/ticketDiscoLogo.svg"
import devotoTicket from "assets/icons/devoto/ticketDevotoLogo.svg"
import geantTicket from "assets/icons/geant/ticketGeantLogo.svg"
import { Brands } from "gdu-vq-common/src/api/interfaces/shared/models/Brand"

const welcomeSteps = [
    { step: 1, icon: personWhite, desc: "Ingresás con tu documento.", },
    { step: 2, icon: pointerSelect, desc: "Elegís donde hacer el pedido.", },
    { step: 3, icon: cart, desc: "Seleccionás  los productos y confirmás tu pedido.", },
    { step: 4, icon: watch, desc: "En el tiempo estimado, retirás con el código brindado.", },
]

export const getStartedUtils = {
    welcomeSteps,
}

export const ticketByBrand = {
    [Brands.disco]: discoTicket,
    [Brands.devoto]: devotoTicket,
    [Brands.geant]: geantTicket,
} as const