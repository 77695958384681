import React from "react"
import { Modals } from "styles/ModalStyle"
import Loader from "./Loader"
import ModalComp from "./ModalComp"
import { Content, SubTitle, Title } from "./styles/LodingModalStyles"

const LoadingModal = ({ isOpen = true, onClose, }: { isOpen?: boolean, onClose?: () => void, }) => {
  return (
    <ModalComp
      modalIsOpen={isOpen}
      modalStyle={Modals.unStyled}
      closeModal={() => { onClose ? onClose() : () => { } }}
    >
      <Content>
        <Loader />
        <Title>Cargando</Title>
        <SubTitle>Aguarde un momento por favor</SubTitle>
      </Content>
    </ModalComp>)
}

export default LoadingModal