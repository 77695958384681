import styled from "styled-components"

export const Hr = styled.hr`
    height: 1px;
    color: #E0E0E0;
    margin-bottom: 13px;
`

export const ThreeInfoWrapper = styled.div`
    border: 1px solid #E0E0E0;
    box-shadow: 0px 23px 33px -24px rgba(0, 0, 0, 0.25);
    border-radius: 13px;
    box-sizing: border-box;
    width: 100%;
    background: #FFFFFF;
    margin-bottom: 20px;
`

export const FirstWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
`

export const InfoWrapper = styled.div`
  max-width: 170px;
  align-self: center;
`
export const RedButtonWrapper = styled.div`
    border: 2px ${({ theme, }) => theme.palette.common.red} solid;
    padding: 4px;
    border-radius: 5px;
    display: flex;
    width: 80%;
    justify-content: center;
`

export const RedButtonText = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: ${({ theme, }) => theme.palette.common.red};
  text-transform: capitalize;
`

export const FirstText = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.02em;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.45);
`

export const FirstHeader = styled.p`
    font-weight: 600;
    font-size: 27px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;   
    margin-bottom: 5px;
`

export const SecondAndThirdWrapper = styled.div`
    border-top: 1px solid rgb(130 130 130 / 25%);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #006937;
`

export const SecondWrapper = styled.div`
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    border-right: 1px solid rgb(130 130 130 / 25%);
    flex: 1;
    padding: 13px 0 10px 0;
    min-height: 73px;

    .sectionImg {
      width: 28px;
      max-height: 28px;
      margin-bottom: 3px;
      fill: #006937;
      stroke: #006937;
    }
`

export const SecondText = styled.p`
    width: 100%;
    font-weight: 500;
    font-size: 15px;
    line-height: 140%;
    letter-spacing: -0.02em;
    opacity: 0.7;
`

export const ThirdWrapper = styled.div`
    padding: 8px;
    flex: 1;
    text-align: center;
`

export const ThirdHeader = styled.p`
    font-weight: 600;
    font-size: 27px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #006937;
`

export const ThirdText = styled.p`
    font-size: 15px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #006937;
    opacity: 0.7;
`