import React from "react"
import { ReactComponent as Tickicon } from "assets/icons/tick_icon.svg"
import {
    DivIcon,
    OrgDiv,
    StyledDiv,
    TextP,
    TimeP
} from "./styles/PreparationTimeStyles"

type PropsType = {
    time: number,
    completed?: boolean,
}
export const PreparationTime = ({
    time,
    completed,
}: PropsType) => {

    return (<StyledDiv>
        <>
            <OrgDiv>
                <TimeP>
                    {completed ? <DivIcon> <Tickicon /> </DivIcon> : time}
                </TimeP>
                <TextP>
                    {completed ? "Pedido completado" : `Min${time === 1 ? "" : "s"}. de espera`}
                </TextP>
            </OrgDiv>
        </>
    </StyledDiv>
    )
}