import { motion } from "framer-motion"
import styled from "styled-components"

type WrapperPropsType = {
  outline?: boolean,
  small?: boolean,
}

export const Wrapper = styled.button<WrapperPropsType>`
  width:100%;
  padding: ${({ small, }) => (small ? "11.5px 0" : "17.5px 0")};
  border: ${({ outline, theme, }) => (outline ? `1px solid ${theme.palette.common.black}` : "none")};
  font-size: ${({ small, }) => (small ? "12px" : "18px")};
  background: ${({ outline, theme, }) => (outline ? "none" : theme.palette.common.black)};
  color: ${({ outline, theme, }) => (outline ? theme.palette.common.black : theme.palette.common.white)};
  border-radius:${({ small, }) => (small ? "14px" : "16px")};
  
  &:disabled{
    background-color:${({ theme, }) => theme.palette.common.offWhite};
    color:${({ theme, }) => theme.palette.common.grey1};
  }

  `

type ContainerProps = {
  disabled?: boolean,
  primary?: boolean,
  color?: string,
  backgroundColor?: string,
  withoutBorder: boolean,
}

export const Container = styled(motion.button) <ContainerProps>`
    height: 60px;
    font-size:16px ;
    font-weight: 600;
    border-radius: 14px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    line-height: 140%;
    letter-spacing: -0.02em;
    border: ${({ primary, theme, withoutBorder, }) => (primary || withoutBorder ? "none" : `solid 2px ${theme.palette.primary.main}`)};

    border-radius: 14px;

    color: ${({ primary, theme, }) => (primary
    ? theme.palette.common.white
    : theme.palette.primary.main)};
    background: ${({ primary, theme, color, disabled, }) => {
    if (disabled) {
      return theme.palette.common.grey7
    }
    if (color) {
      return color
    }
    if (primary) {
      return theme.palette.primary.main
    }
    return "none"
  }
  };
    
    
`