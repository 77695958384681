import React from "react"
import { StyledButton, StyledButtonColor } from "components/displays/Elements"

import { CustomizedDiv,
EstimatedTimeDiv,
DividerCarrito,
PriceP,
EstimatedTextP,
EstimatedPriceDiv,
EstimatedDiv,
 } from "../Style/ShoppingCartStyle"
import { useRecoilValue } from "recoil"
import { sectionSelectAtom } from "state"
import { PreparationTime } from "components/displays/PreparationTime"

const FooterCartItems = (props: {
  disabled: boolean,
  handleTotalAmountCarrito: () => string,
  modalConfirmOrder: () => void,
}) => {
  const section = useRecoilValue(sectionSelectAtom)!

  return (
    <CustomizedDiv>
      {!props.disabled && (
        <EstimatedDiv>
          <EstimatedTimeDiv>
            <PreparationTime
              time={section.waitTime}
            />
          </EstimatedTimeDiv>
          <DividerCarrito />
          <EstimatedPriceDiv>
            <PriceP>
              <p>$</p>
              {props.handleTotalAmountCarrito()}
            </PriceP>
            <EstimatedTextP>
              Total estimado
            </EstimatedTextP>
          </EstimatedPriceDiv>
        </EstimatedDiv>
      )}
      <StyledButtonColor>
        <StyledButton
          onClick={props.modalConfirmOrder}
          fontSmall={true}
          fontLight={false}
          disabled={props.disabled}
        >
          Enviar pedido
        </StyledButton>
      </StyledButtonColor>
    </CustomizedDiv>
  )
}

export default FooterCartItems
