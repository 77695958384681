import { UserInfo, Cart } from "types"

const storePrefix = "@"

const getAccessors = <T>(keyParam: string, storage: Storage, getInitial: () => T) => {
    const keyStorage = `${storePrefix}${keyParam}`
    const getter = (): T => {
        const content = storage[keyStorage]
        if (content) {
            return JSON.parse(content)
        } else {
            const value: T = getInitial()
            if (value) {
                storage[keyStorage] = JSON.stringify(value)
            }
            return value
        }
    }

    const setter = (content: T) => {
        storage[keyStorage] = JSON.stringify(content)
    }

    const deleter = () => {
        storage.removeItem(keyStorage)
    }

    return [getter, setter, deleter,] as const
}

export const [getReadyVisit, setReadyVisit, deleteReadyVisit,] = getAccessors<boolean>(
    "readyVisit", localStorage, () => (false)
)

export const [getUserInfo, setUserInfo, closeUserSession,] = getAccessors<UserInfo | null>(
    "consumer", localStorage, () => (null)
)

export const [getSectionId, setSectionId, deleteSectionSelectId,] = getAccessors<string | null>(
    "consumerSection", sessionStorage, () => (null)
)

export const [getCart, setCart, deleteCart,] = getAccessors<Cart | null>(
    "consumerCart", sessionStorage, () => (null)
)