import React, { ReactChild, useState } from "react"
import Modal from "react-modal"

Modal.setAppElement("#root")

const ModalComp = (props: {
    children: ReactChild | ReactChild[],
    closeModal?: () => void,
    modalIsOpen: boolean,
    modalStyle?: Modal.Styles | undefined,
}) => {
    const [showModal, setShowModal,] = useState(props.modalIsOpen)
    const onDismiss = () => {
        if (props.closeModal) {
            props.closeModal!()
        } else {
            setShowModal(false)
        }
    }

    return <Modal
        isOpen={showModal}
        onRequestClose={onDismiss}
        style={props.modalStyle}
    >
        {props.children}
    </Modal >
}

export default ModalComp