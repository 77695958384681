import React from "react"
import loader from "assets/images/loader.svg"
import loaderBackground from "assets/images/loader-background.svg"
import { Piece, Wrapper } from "./styles/LoderStyles"

const Loader = () => {
  return (<Wrapper>

    <Piece src={loaderBackground} />
    <Piece src={loader} />
  </Wrapper>)
}

export default Loader