
import { generateUrls } from "gdu-vq-common/src/api/urls/ApiUrlsConsumer"
import { EnvHelper } from "../util/EnvHelper"
import { getControllerProxy, ProxyOptions } from "gdu-vq-common/src/api/ApiProxy"
import {
    IConsumerSessionController
} from "gdu-vq-common/src/api/interfaces/consumer/ConsumerAccountController"
import {
    IConsumerStoreController
} from "gdu-vq-common/src/api/interfaces/consumer/CustomerStoreController"
import {
    IConsumerOrderController
} from "gdu-vq-common/src/api/interfaces/consumer/ConsumerOrderController"
import { getUserInfo, closeUserSession, setUserInfo, } from "services/session"
import { getStoreBrand } from "util/paths"
const getUrls = generateUrls(() => EnvHelper.baseUrlCore)

const redirectToLogin = () => {
    const { brand, storeSlug, } = getStoreBrand()!
    window.location.href = `${window.location.origin}/${brand}/${storeSlug}`
}


const options: ProxyOptions = {
    getApiCode: () => EnvHelper.apiCodeCore,
    getToken: () => getUserInfo()?.token,
    onAuthError: () => {
        closeUserSession()
        redirectToLogin()
    },
    onRefreshToken: (refresh) => {
        const storageContent = getUserInfo()
        if (storageContent) {
            setUserInfo({ ...storageContent, token: refresh.token, })
        } else {
            closeUserSession()
            redirectToLogin()
        }
    },
}

export const SessionClient = getControllerProxy<IConsumerSessionController>(
    getUrls().consumerAccount,
    options
)

export const StoreBrands = getControllerProxy<IConsumerStoreController>(
    getUrls().consumerStore,
    options
)

export const OrderProducts = getControllerProxy<IConsumerOrderController>(
    getUrls().consumerOrder,
    options
)