import React from "react"
import ProductList from "./productList/ProductList"
import { Route, useNavigate, } from "react-router-dom"
import {
    StoreSectionProducts,
} from "gdu-vq-common/src/api/interfaces/consumer/models/ConsumerStore"
import ProductDetail from "./productList/ProductDetail"
import { pathsDefinition, usePathsNavigator } from "util/paths"
import RootRoutes from "components/utils/GenerateAbsolutePath"
import { productsSelector, sectionSelectAtom } from "state"
import { useRecoilValueLoadable } from "recoil"
import { useLoadableValueHelper } from "hooks/useLoadableValueHelper"
import LoadingModal from "components/displays/LoadingModal"
import ModalComp from "components/displays/Modal"
import { Modals } from "styles/ModalStyle"
import { useTrackEvent } from "hooks/useTrackEvent"


const Products = () => {
    const navigate = useNavigate()
    const paths = usePathsNavigator()
    const { clickProduct, } = useTrackEvent()
    const response = useLoadableValueHelper(useRecoilValueLoadable(productsSelector))
    const section = useLoadableValueHelper(useRecoilValueLoadable(sectionSelectAtom))
    const handleOnProductDetail = (product: StoreSectionProducts | undefined) => {
        if (product) {
            clickProduct(product.description)
            navigate(paths("productDetail", { "product_id": product.id, }))
        } else {
            navigate(paths("products"))
        }
    }

    if (response.loading || section.loading) { return <LoadingModal isOpen={true} /> }

    const renderProductDetails = () => {
        return (<>
            <ProductList
                onProductDetail={(product) => handleOnProductDetail(product)}
            />
            <ModalComp
                modalIsOpen={true}
                modalStyle={Modals.screen}
                closeModal={
                    () => navigate(paths("products"))
                }
            >
                <ProductDetail
                    backPath="products"
                />
            </ModalComp>
        </>
        )
    }

    return <RootRoutes>
        <Route
            path={pathsDefinition.products}
            element={
                <ProductList
                    onProductDetail={(product) => handleOnProductDetail(product)}
                />}
        />
        <Route
            path={pathsDefinition.productDetail}
            element={renderProductDetails()}
        />
    </RootRoutes>
}

export default Products
