import { discoImg, devotoImg, geantImg, } from "components/utils/brandsImg/disco/imgDisco"
import { Brands } from "types"
import butcherShop from "assets/icons/sections/butcherShop.png"
import bakery from "assets/icons/sections/bakery.png"
import cheesShop from "assets/icons/sections/altProductCheeseShop.svg"
import delicatessen from "assets/icons/sections/delicatessen.png"
import fishShop from "assets/icons/sections/fishShop.png"
import defProductButcherShop from "assets/icons/sections/altProductButcherShop.png"
import defProductBakery from "assets/icons/sections/altProductBakery.png"
import defProductCheeseShop from "assets/icons/sections/altProductCheeseShop.svg"
import defProductDelicatessen from "assets/icons/sections/altProductDelicatessen.svg"
import defProductFishShop from "assets/icons/sections/altProductFishShop.png"
import { SectionType } from "gdu-vq-common/src/api/interfaces/shared/models/Section"
import defProductFishShopGreen from "assets/icons/sections/altProductFishShopGreen.png"
import defProductButcherShopGreen from "assets/icons/sections/altProductButcherShopGreen.png"
import defProductBakeryGreen from "assets/icons/sections/altProductBakeryGreen.png"
import defProductCheeseShopGreen from "assets/icons/sections/altProductCheeseShopGreen.svg"

export const imgByBrand = {
    [Brands.disco]: discoImg,
    [Brands.devoto]: devotoImg,
    [Brands.geant]: geantImg,
} as const

export const sectionImg = {
    [SectionType.carniceria]: butcherShop,
    [SectionType.panaderia]: bakery,
    [SectionType.pescaderia]: fishShop,
    [SectionType.quesosFiambres]: cheesShop,
    [SectionType.rotiseria]: delicatessen,
} as const

export const defaultProductImg = {
    [SectionType.carniceria]: defProductButcherShop,
    [SectionType.panaderia]: defProductBakery,
    [SectionType.pescaderia]: defProductFishShop,
    [SectionType.quesosFiambres]: defProductCheeseShop,
    [SectionType.rotiseria]: defProductDelicatessen,
} as const

export const defaultProductImgGreen = {
    [SectionType.carniceria]: defProductButcherShopGreen,
    [SectionType.panaderia]: defProductBakeryGreen,
    [SectionType.pescaderia]: defProductFishShopGreen,
    [SectionType.quesosFiambres]: defProductCheeseShopGreen,
    [SectionType.rotiseria]: defProductDelicatessen,
} as const