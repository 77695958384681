import styled, { css } from "styled-components"

const ContainerDiv = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
`

const WrapperDiv = styled.div`
    transition: height 2s;
`

const DatosTitle = styled.div`
    padding-top: 16px;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.1px;
    text-transform: uppercase;
`

const DatosDiv = styled.div`
    max-height: max-content;
    padding: 12px;
    background-color: ${({ theme, }) => theme.palette.common.grey};
    border-radius: ${({ theme, }) => theme.borderRadius};
    margin-top: 6px;
`

const OrderDiv = styled.div`
    display: flex;
    padding: 4px;
    justify-content: space-between;
`

const OrderDetailDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const Codigo = styled.h2`
    font-weight: 600;
    font-size: 26px;
    text-transform: uppercase;
    line-height: 120%;
    letter-spacing: -0.35px;
`

const CodigoDesc = styled.p`
    font-size: 9px;
    line-height: 130%;
    letter-spacing: -0.2px;
    text-transform: uppercase;
`

const SectionDescDiv = styled.div`
    display: flex;
    flex: row;
    align-items: left;
`

const SectionDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    max-height: max-content;
    margin-left: 16px;
`

const SectionImg = styled.img`
    background-color: ${({ theme, }) => theme.palette.old.primary.main};
    border-radius: 8px;
    width: 40px;
    height: 40px;
    padding: 6px;
`

const SectionText = styled.p`
    font-size: 18px;
    line-height: 1.5;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 140%;
    letter-spacing: -0.1px;
`

const InfoBrandDiv = styled.div`
    display: flex;
    text-transform: uppercase;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
`

const BrandImg = styled.img`
    margin-right: 8px;
    max-height: 9px;
    align-self: center;
    display: inline;
`

const BrandP = styled.p`
    font-weight: 600;
    margin-right: 5px;
    font-size: 11px;
    ::after { 
        content: " |";
    };
    line-height: 130%;
    letter-spacing: -0.35px;
    display: inline;
`

const LocationP = styled.p`
    font-size: 11px;
    line-height: 130%;
    display: inline;
    letter-spacing: -0.35px;
`
type ArrowImgProps = {
    rotate: boolean,
}
const ArrowImg = styled.img<ArrowImgProps>`
    margin-right: 8px;
    max-width:10px;
    max-height:10px;
    transition:all 0.3s;
    align-self: center;
    ${({ rotate, }) => rotate && css`transform:rotate(90deg)`}
`

const ProcessDiv = styled.div`
    width: 100%;
`

const ProcessTextDiv = styled.div`
    display: flex;
    justify-content: left;
    margin: 4px 0px;
    padding: 12px;
    background-color: ${({ theme, }) => theme.palette.old.primary.main};
    border-radius: ${({ theme, }) => theme.borderRadius};
`

const ProcessText = styled.p`
    font-weight: 500;
    font-size: 11px;
    line-height: 130%;
    letter-spacing: -0.3px;
    color: ${({ theme, }) => theme.palette.common.offWhite};
    text-transform: uppercase;
`

const StepsContainer = styled.div`
    transition: 3s;
`

const StepDiv = styled.div`
    background-color: ${({ theme, }) => theme.palette.common.grey};
	display: flex;
    flex-direction: row;
    padding: 16px;
    align-items: center;
    border-radius: 20px;
    margin-bottom: 8px;
`

const StepDivIcon = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${({ theme, }) => theme.palette.old.primary.main};
    height: 45px;
    width: 45px;
    border-radius: 50%;
    padding:16px;
    align-items:center;
    justify-content: center;
`

const StepIcon = styled.img`
    max-height: 24px;
    max-width: 24px;
`

const StepDesc = styled.p`
    max-height: 100%;
    max-width: 80%;
    font-size: 14px;
    padding-left: 16px;
    line-height: 140%;
    letter-spacing: -0.3px;
`

const ButtomDiv = styled.div`
    width: 100%;
`

const StyledButton = styled.button`
  display: block;
  width: 100%;
  color: ${({ theme, }) => theme.palette.common.white};
  background-color: ${({ theme, }) => theme.palette.common.black};
  &:disabled {
    background-color: ${({ theme, }) => theme.palette.common.grey3};
  }
  &:hover {
    background-color: ${({ theme, }) => theme.palette.common.black1};
  }
`

const customizedStyleButton = {
    color: "${({ theme, }) => theme.palette.common.white}",
    padding: "18px 0 18px 0",
    fontSize: "18px",
    lineHeight: "140%",
    fontWeight: "500",
    borderRadius: "16px",
}


export const ShoppingConfirmCartStyle = {
    ContainerDiv,
    WrapperDiv,
    DatosTitle,
    DatosDiv,
    OrderDiv,
    OrderDetailDiv,
    Codigo,
    CodigoDesc,
    SectionDiv,
    SectionDescDiv,
    SectionImg,
    SectionText,
    InfoBrandDiv,
    BrandImg,
    BrandP,
    LocationP,
    ArrowImg,
    ProcessDiv,
    ProcessTextDiv,
    ProcessText,
    StepsContainer,
    StepDiv,
    StepDivIcon,
    StepIcon,
    StepDesc,
    ButtomDiv,
    StyledButton,
    customizedStyleButton,
}