/* eslint-disable max-lines-per-function */
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { StoreSection } from "gdu-vq-common/src/api/interfaces/consumer/models/ConsumerStore"
import Sections from "../sectionList/SectionsList"
import ConfirmSectionChange from "../sectionConfirm/ConfirmSectionChange"
import { Modals } from "styles/ModalStyle"
import useCart from "hooks/useCart"
import { sectionSelectAtom, selectedSectionIdAtom, assertStoreSelector } from "state"
import { useRecoilState, useRecoilValueLoadable, } from "recoil"
import { Wrapper } from "./SectionChangeStyles"
import { useLoadableValueHelper } from "hooks/useLoadableValueHelper"
import LoadingModal from "components/displays/LoadingModal"
import NotFound from "pages/notFound/NotFound"
import { usePathsNavigator } from "util/paths"
import Modal from "components/displays/Modal"

const SectionChange = () => {
    const navigate = useNavigate()
    const paths = usePathsNavigator()
    const { cartLenght, clearCart, } = useCart()
    const responseStore = useLoadableValueHelper(useRecoilValueLoadable(assertStoreSelector))
    const [sectionId, setSectionId,] = useRecoilState(selectedSectionIdAtom)
    const sectionSelect = useLoadableValueHelper(useRecoilValueLoadable(sectionSelectAtom))
    const [sectionChange, setSectionChange,] = useState<StoreSection>()
    const [changeSection, setChangeSection,] = useState(false)

    if (responseStore.error) { throw new Error("Invalid Store") }



    const handleSelectedSection = (clear: boolean) => {
        if (clear) {
            const newSectionId = responseStore.data?.sections.find(
                s => s.id === sectionChange?.id
            )?.id!
            setSectionId(newSectionId)
            clearCart(newSectionId)
        }
        navigate(paths("products"))
    }

    React.useEffect(() => {
        const handleSelectSection = () => {
            if (sectionChange?.id === sectionId) {
                handleSelectedSection(false)
            } else if (cartLenght) {
                setChangeSection(true)
            } else {
                setSectionId(
                    responseStore.data?.sections.find(s => s.id === sectionChange?.id)?.id!)
                handleSelectedSection(false)
            }
        }

        sectionChange && handleSelectSection()
    }, [sectionChange,
        responseStore,
        setSectionId,
        cartLenght,
        setChangeSection,
        handleSelectedSection,])

    if (responseStore.loading || sectionSelect.loading) { return <LoadingModal isOpen={true} /> }

    if (responseStore.data.sections.length > 0) {
        return <>
            <Wrapper>
                <Sections
                    onClick={(section: StoreSection) =>
                        setSectionChange(section)
                    }
                    selectedSection={sectionChange || sectionSelect.data!}
                    store={responseStore.data!}
                />
            </Wrapper>
            <Modal
                modalIsOpen={changeSection}
                modalStyle={Modals.confirm}
                closeModal={() => {
                    setChangeSection(false)
                    setSectionChange(undefined)
                }}
            >
                {sectionChange && sectionSelect.data ? <ConfirmSectionChange
                    itemsInCart={cartLenght}
                    currentSection={sectionSelect.data.sectionType}
                    newSection={sectionChange.sectionType}
                    onCancel={() => {
                        setChangeSection(false)
                        setSectionChange(undefined)
                    }}
                    onDone={() => handleSelectedSection(true)}
                /> : <></>}
            </Modal>
        </>
    } else {
        return <NotFound msg="Este supermercado no dispone de secciones" />
    }
}

export default SectionChange