import styled from "styled-components"

export const TitleDiv = styled.div`
    display: flex;
    justify-content: space-between;
`

export const TitleHomeProducts = styled.h2`
  display: -webkit-box;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: -0.5px;
  color: ${({ theme, }) => theme.palette.common.black};
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const FilterDiv = styled.div`
    margin-top: 8px;
    display: flex;
    background-color: ${({ theme, }) => theme.palette.common.grey};
    border-radius: ${({ theme, }) => theme.borderRadius};
    padding: 0 16px;
    border: 2px solid transparent;
    :hover{
        border: 2px solid ${({ theme, }) => theme.palette.common.grey5};
    }
`

export const FilterInput = styled.input`
    width: 100%;
    padding: 8px;
    background-color: ${({ theme, }) => theme.palette.common.grey};
    border: 1px solid transparent;
    border-radius: ${({ theme, }) => theme.borderRadius};
    font-size: 16px;
    :focus{
        outline: none;
    }
    :hover{
        border: 1px solid transparent;
    }
`

export const ProductsDiv = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 10px;
    &&:first-child{
        padding-top: 165px;
    }
`

export const FilterMsgP = styled.p`
    padding: 160px 40px;
    text-align: center;
    font-size: 14px;
`


export const HideScrollBar = styled.div`
    height:100%;
    .List{
        ::-webkit-scrollbar{
            display: none;
        }
    }
`

type LinePropsType = {
    visible?: boolean,
}
export const Line = styled.div<LinePropsType>`
    opacity: ${({ visible, }) => (visible ? 1 : 0)};
    width: 100%;
    height: 1px;
    background:${({ theme, }) => theme.palette.common.grey7};
    position:absolute;
    top:146px;
    left:0;
    z-index: 1;
    transition:all 0.3s;
`