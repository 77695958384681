import React from "react"
import {
    StoreSectionProducts
} from
    "gdu-vq-common/src/api/interfaces/consumer/models/ConsumerStore"
import {
    productReadablePrice,
    productUnitToReadableSingularText,
} from "gdu-vq-common/src/api/interfaces/shared/helpers/ProductUtil"
import {
    AddButton,
    AddDiv,
    ContainerDiv,
    DescriptionP,
    DescriptionPWrapper,
    ItemDiv,
    PriceDiv,
    PriceP,
    ProductImgDiv,
    ProductItemImgDiv,
} from "./ProductListStyle/ProductItemStyle"
import { useRecoilValueLoadable, } from "recoil"
import { sectionSelectAtom } from "state"
import { useLoadableValueHelper } from "hooks/useLoadableValueHelper"
import LoadingModal from "components/displays/LoadingModal"
import ProductImg, { ImgType } from "util/ProductImg"
import { ReactComponent as Agregar } from "assets/icons/plus_icon.svg"
import { DefaultTheme, withTheme } from "styled-components"

const ProductItem = (props: {
    productItem: StoreSectionProducts,
    onClick: () => void,
    theme: DefaultTheme,
}) => {
    const { description, price, unit, imageUrl, } = props.productItem
    const section = useLoadableValueHelper(useRecoilValueLoadable(sectionSelectAtom))

    if (section.loading) { return <LoadingModal /> }


    return <>
        <ItemDiv
            onClick={props.onClick}
        >
            <ProductImgDiv>
                <ProductItemImgDiv>
                    <ProductImg
                        imgStyle={ImgType.productListItem}
                        imageUrl={imageUrl}
                        sectionType={section.data!.sectionType}
                    />
                </ProductItemImgDiv>
            </ProductImgDiv>
            <ContainerDiv>
                <AddDiv>
                    <DescriptionPWrapper>
                        <DescriptionP>{description}</DescriptionP>
                    </DescriptionPWrapper>
                    <PriceDiv>
                        <PriceP>
                            {productReadablePrice(price)} {" "}
                            por {productUnitToReadableSingularText(unit)}
                        </PriceP>
                    </PriceDiv>
                </AddDiv>
                <AddButton>
                    <Agregar fill={props.theme.palette.primary.main} /> Agregar
                </AddButton>
            </ContainerDiv>
        </ItemDiv>
    </>
}

export default withTheme(ProductItem)