import styled from "styled-components"

export const Container = styled.div`
    background-color: #ffffffb0;
    border-top: solid 2px rgba(0, 0, 0, 0.11);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    width: 100%;
    bottom: 0;
    padding:8px; 
    box-sizing: border-box;
    backdrop-filter: blur(40px);
`
export const FooterContainer = styled.div`
    position: fixed;
    display: flex;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 4;
`