import { useMemo } from "react"
import { Loadable } from "recoil"



export const useLoadableValueHelper = <T,>(data: Loadable<T>) => {
    return useMemo(() => {
        if (data.state === "hasError") {
            return {
                error: true,
                loading: false,
                data: undefined,
            } as const
        } else if (data.state === "loading") {
            return {
                error: false,
                loading: true,
                data: undefined,
            } as const
        } else {
            return {
                error: false,
                loading: false,
                data: data.contents,
            } as const
        }
    }, [data,])
}

