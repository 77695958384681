import styled from "styled-components"
import { motion } from "framer-motion"

type ItemProps = {
    active: boolean,
}
export const Container = styled(motion.div)`
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 100%;
    justify-content: center;
    margin-bottom:8px;
`
export const Item = styled(motion.div) <ItemProps>`
    width: 6px;
    height: 6px;
    border:none;
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.13);
    width: ${({ active, }) => (active ? "15px" : "6px")};
    background-color: ${({ active, theme, }) => (active
        ? theme.palette.secondary.main
        : "rgba(0, 0, 0, 0.13)")}
`