import React from "react"


const LoginError = ({ serverError, }: { serverError?: boolean, }) => {
    return serverError
        ? (<div>
            <p>Algo salió mal, inténtalo de nuevo más tarde.</p>
        </div>)
        : (<div>
            <p>
                EL DOCUMENTO NO SE ENCUENTRA REGISTRADO EN
                EL PROGRAMA DE FIDELIZACIÓN.
            </p>
            <p style={{ marginTop: 15, }}>
                Para ser parte del programa, registráte en
                la sección de atención al cliente de la sucursal.
            </p>
        </div>)
}

export default LoginError