import { useCallback } from "react"
import { useRecoilState } from "recoil"
import { closeUserSession, setUserInfo, setReadyVisit } from "services/session"
import { isLoggedAtom } from "state"
import { UserInfo } from "types"

const useSession = () => {
    const [isLogged, setIsLogged,] = useRecoilState(isLoggedAtom)

    const handleInitSession = useCallback((content: UserInfo) => {
        setUserInfo(content)
        setIsLogged(true)
        setReadyVisit(true)
    }, [setIsLogged,])

    const finishSession = useCallback(() => {
        closeUserSession()
        setIsLogged(false)
    }, [setIsLogged,])

    return { isLogged, handleInitSession, finishSession, }
}

export default useSession
