import styled from "styled-components"

export const Content = styled.div`
  width:219px;
  height:147px;
  background-color: ${({ theme, }) => theme.palette.common.offWhite1};
  display:flex;
  justify-content:center;
  flex-direction:column;
  align-items:center;
  border-radius:24px;
  backdrop-filter: blur(10px);
`

export const Title = styled.p`
  font-size:16px;
  font-weight:500;
  margin-top:7px;
  color: #4f4f4f;
`
export const SubTitle = styled.p`
  font-size:12px;
  font-weight:400;
  margin-top:3px;
  color: #828282;
`