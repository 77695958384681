import { EnvHelper } from "../util/EnvHelper"
import {
    ORTC_Direction,
    ORTC_Messages,
} from "gdu-vq-ordering-api-common/src/messages/OrchestratorToConsumer"
import {
    CTOR_Messages,
} from "gdu-vq-ordering-api-common/src/messages/ConsumerToOrchestrator"
import { RTClient } from "gdu-vq-ordering-api-common/src/utils/RTClient"
import {
    ORTOS_Direction, ORTOS_Messages
} from "gdu-vq-ordering-api-common/src/messages/OrchestratorToStore"


export const OrchestratorClient = new RTClient<
    ORTC_Messages | ORTOS_Messages, CTOR_Messages
>(EnvHelper.baseUrlRT, [ORTC_Direction, ORTOS_Direction,])
