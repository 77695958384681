import React, { useEffect } from "react"
import { Navigate, Route, } from "react-router-dom"
import Orders from "pages/order/Orders"
import Section from "pages/sections/Section"
import { Observer } from "../pages/observer/Observer"
import Products from "pages/products/Products"
import ToastProvider from "components/providers/ToastProvider"
import ShoppingCart from "pages/shoppingcart/ShoppingCart"
import { pathsDefinition, usePathsNavigator, } from "util/paths"
import RootRoutes from "components/utils/GenerateAbsolutePath"
import OrderSent from "pages/shoppingcart/confirmCart/OrderSent"
import { useLoadableValueHelper } from "hooks/useLoadableValueHelper"
import { useRecoilValueLoadable } from "recoil"
import { assertStoreSelector } from "state"
import LoadingModal from "components/displays/LoadingModal"
import { getUserInfo } from "services/session"
import { trackEvent } from "util/MixPanelEventsHelper"

const Protected = () => {
        useEffect(() => {
                trackEvent.identifyUser(getUserInfo()!.userCI)
        }, [])
        const paths = usePathsNavigator()
        const responseStore = useLoadableValueHelper(useRecoilValueLoadable(assertStoreSelector))
        if (responseStore.error) { throw new Error("Invalid Store") }

        if (responseStore.loading) {
                return <LoadingModal isOpen={true} />
        }

        return <ToastProvider>
                <Observer>
                        <RootRoutes>
                                <Route path={pathsDefinition.section} element={<Section />} />
                                <Route path={`${pathsDefinition.products}/*`} element={<Products />} />
                                <Route path={`${pathsDefinition.shoppingCart}/*`} element={<ShoppingCart />} />
                                <Route path={pathsDefinition.orders} element={<Orders />} />
                                <Route path={pathsDefinition.orderSent} element={<OrderSent />} />
                                <Route
                                        path={pathsDefinition.notFound}
                                        element={<Navigate to={paths("section")} />}
                                />
                        </RootRoutes>
                </Observer>
        </ToastProvider>
}

export default Protected