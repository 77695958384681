/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
import step_one from "../../assets/step_one.svg"
import step_two from "../../assets/step_two.svg"
import step_three from "../../assets/step_three.svg"
import step_four from "../../assets/step_four.svg"
import hipercard from "../../assets/hipercard.png"
import mas from "../../assets/mas.png"
import OnboardingPage from "./components/OnboardingPage"
import Document from "../login/Document"
import ActivePage from "./components/ActivePage"
import Button from "../../components/displays/Button"
import {
    Actions, Container, Description, Scroll, Title, Welcome, WelcomeContent,
    HeaderText, HeaderWrapper,
} from "../getStarted/styles/GetStartedStyles"
import ModalComp from "components/displays/Modal"
import { Modals } from "styles/ModalStyle"
import ModalContent from "./components/ModalContent"
import { trackEvent, } from "util/MixPanelEventsHelper"
import { ticketByBrand } from "./utils/getStartedUtils"
import { getStoreBrand } from "util/paths"
import { Brands } from "gdu-vq-common/src/api/interfaces/shared/models/Brand"



export const pageMotion = {
    initial: { opacity: 0, y: 10, },
    animate: { opacity: 1, y: 0, transition: { duration: 0.16, }, },
    exit: { opacity: 0, y: 0, transition: { duration: 0.16, }, },
}

const steps = [
    {
        title: "Paso 1 de 4",
        description: "Ingresá tu cédula de identidad.",
        img: <motion.img height={200} src={step_one} alt="Filas virtuales" />,
    },
    {
        title: "Paso 2 de 4",
        description: "Elige entre las secciones habilitadas en este momento, en cual deseas comprar.",
        img: <motion.img
            initial={{ opacity: 0, }}
            animate={{ opacity: 1, }}
            height={200}
            src={step_two}
            alt="Filas virtuales" />,
    },
    {
        title: "Paso 3 de 4",
        description: "Seleccioná los productos y confirmá tu pedido.",
        img: <motion.img height={200} src={step_three} alt="Filas virtuales" />,
    },
    {
        title: "Paso 4 de 4",
        description: "Seguí comprando y retirá cuando te avisemos que está listo.",
        img: <motion.img height={200} src={step_four} alt="Filas virtuales" />,
    },
]

const preloadImages = (imagesSrc: string[]) => {
    imagesSrc.forEach(img => {
        const image = new Image()
        image.src = img
    })
}

const GetStarted = (props: { initialStep: number, }) => {
    const { initialStep, } = props
    const [activeStep, setActiveStep,] = useState(initialStep)
    const [displayModal, setDisplayModal,] = useState(false)
    const brandSlug = getStoreBrand()!.brand! as Brands

    useEffect(() => {
        trackEvent.viewWelcomePage()
    }, [])

    const handleNoAfiliateClick = () => {
        setDisplayModal(true)
        trackEvent.noAfiliated()
    }

    const handleSkipTutorialClick = () => {
        setActiveStep(5)
        trackEvent.skipTutorial()
    }

    const handleStartTutorialClick = () => {
        setActiveStep(1)
    }

    const renderHeader = () => {
        return <HeaderWrapper>
            {(activeStep >= 1 && activeStep <= 3)
                && <HeaderWrapper onClick={handleSkipTutorialClick}>
                    <HeaderText>Saltar tutorial</HeaderText>
                </HeaderWrapper>}
            {(activeStep === 5)
                && <HeaderWrapper onClick={handleStartTutorialClick}>
                    <HeaderText >¿Cómo funciona?</HeaderText>
                </HeaderWrapper>}
        </HeaderWrapper>
    }

    const renderActions = () => {
        let tutorialBtnLbl = "¿Cómo funciona?"
        if (activeStep >= 1 && activeStep <= 3) {
            tutorialBtnLbl = "Siguiente"
        } else if (activeStep === 4) {
            tutorialBtnLbl = "Realizar mi primer pedido"
        }
        const tutorialButton = (activeStep >= 0 && activeStep <= 4)

        const loyalBtnLbl = "No soy cliente fidelizado, ¿Qué hago?"
        const loyalButton = (activeStep === 0 || activeStep === 5)

        return <Actions>
            {activeStep !== 5 && <ActivePage items={[0, 1, 2, 3, 4,]} active={activeStep} />}
            {tutorialButton && <Button
                onClick={() => setActiveStep(activeStep + 1)}
                primary={true}
                label={tutorialBtnLbl}
            />}
            {loyalButton && <Button
                withoutBorder={activeStep === 5}
                onClick={handleNoAfiliateClick}
                primary={false}
                label={loyalBtnLbl}
            />}
        </Actions>
    }

    return (
        <Container initial="initial"
            animate="animate"
            exit="exit"
            variants={pageMotion}>
            <Scroll>
                {renderHeader()}
                <AnimatePresence onExitComplete={() => preloadImages([step_one, step_two, step_three, step_four,])}>
                    <OnboardingPage key={0} isActive={activeStep === 0}>
                        <Welcome>
                            <div>
                                <motion.img src={ticketByBrand[brandSlug]} alt="Filas virtuales" />
                            </div>
                            <WelcomeContent>
                                <Title>Hola</Title>
                                <Description>
                                    Si sos <b>cliente</b> <motion.img src={mas} alt="logo-mas" style={{ verticalAlign: "text-bottom", height: "20px", }} /> <b>o</b>
                                    <motion.img src={hipercard} alt="logo-hipercard" style={{ verticalAlign: "text-bottom", height: "20px", }} />
                                    hacé tu pedido aquí y seguí comprando mientras los preparamos.
                                </Description>
                            </WelcomeContent>
                        </Welcome >
                    </OnboardingPage>
                    {steps.map(({ title, description, img, }, i) => <OnboardingPage key={i + 1} isActive={activeStep === i + 1}>
                        <Title>{title}</Title>
                        <Description>{description}</Description>
                        <div className="imgWrapper">{img}</div>
                    </OnboardingPage>)}
                    <OnboardingPage key={5} isActive={activeStep === 5}>
                        <div>
                            <motion.img src={ticketByBrand[brandSlug]} alt="Filas virtuales" style={{ maxHeight: "180px", }} />
                        </div>
                        <Document />
                    </OnboardingPage>
                </AnimatePresence>
            </Scroll>
            {renderActions()}
            <ModalComp
                modalIsOpen={displayModal}
                closeModal={() => {
                    setDisplayModal(false)
                }}
                modalStyle={Modals.screen}
            >
                <ModalContent />
            </ModalComp>
        </Container>
    )
}
export default GetStarted
