import styled from "styled-components"

export const Title = styled.h2`
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    line-height: 131%;
    letter-spacing: -0.02em;
    margin-bottom: 44px;
    margin-top: 53px;

`

export const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const Section = styled.div`
    background-color: ${({ theme, }) => theme.palette.common.green2};
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    align-items: center;
    color: ${({ theme, }) => theme.palette.primary.main};
    padding: 16px;
    text-align: center;
    gap: 14px;

    span{
        width: 22px;
        height: 22px;
        border-radius: 50%;
        border: 2px solid ${({ theme, }) => theme.palette.primary.main};
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
    }
`