import React from "react"
import styled from "styled-components"
import ButtonTypes from "./ButtonTypes"
import { ReactComponent as MinusButton} from "assets/icons/minusOutlined.svg"

const StyledRemoveButton = styled.button<{ disable: boolean, }>`
  padding: 8px 19px;
  color: ${({ theme, }) => theme.palette.common.offWhite};
  border-radius: 8px;
  background-color: ${({ theme, disable, }) => (disable
    ? theme.palette.common.grey
    : theme.palette.common.white)};
  :focus{
    box-shadow: 0px 0px 0px 4px rgba(51, 51, 51, 0.25);
  }
  :hover{
    box-shadow: 0px 0px 0px 4px rgba(51, 51, 51, 0.25);
  }
  outline: none;
`

const RemoveButton = (props: ButtonTypes) => {
  return (
    <StyledRemoveButton disable={props.disabled!} {...props} style={{ ...props.extraClasses, }}>
      <MinusButton />
    </StyledRemoveButton>
  )
}

export default RemoveButton
