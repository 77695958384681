import { Styles } from "react-modal"
import { MODALS_ZINDEX } from "./GlobalStyles"

const screen: Styles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: MODALS_ZINDEX,
        backgroundColor: "hsl(0deg 0% 38% / 0.7)",
    },
    content: {
        position: "absolute",
        top: "70px",
        left: "0",
        right: "0",
        bottom: "0",
        overflow: "auto",
        WebkitOverflowScrolling: "touch",
        borderTopRightRadius: "25px",
        borderTopLeftRadius: "25px",
        outline: "none",
        padding: "20px",

    },
}

const confirm: Styles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: MODALS_ZINDEX,
        backgroundColor: "hsl(0deg 0% 38% / 0.7)",
    },
    content: {
        maxBlockSize: "max-content",
        textAlign: "center",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        left: "5%",
        right: "5%",
        minHeight: "207px",
        height: "fit-content",
        WebkitOverflowScrolling: "touch",
        borderRadius: "20px",
        outline: "none",
        padding: "24px",
        inset: "50% 37px 40px",

    },
}

const unStyled: Styles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.35)",
        zIndex: MODALS_ZINDEX,
    },
    content: {
        position: "absolute",
        top: "50%",
        transform: "translateX(-50%) translateY(-50%)",
        left: "50%",
        border: "none",
        width: "fit-content",
        height: "fit-content",
        background: "unset",
        overflow: "unset",

    },
}

const ModalCarrito: Styles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: MODALS_ZINDEX,
        backgroundColor: "hsl(0deg 0% 38% / 0.7)",
    },
    content: {
        maxBlockSize: "max-content",
        textAlign: "center",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        left: "5%",
        right: "5%",
        minHeight: "207px",
        height: "fit-content",
        WebkitOverflowScrolling: "touch",
        borderRadius: "20px",
        outline: "none",
        padding: "10px",
        inset: "78% 10px 40px",

    },
}

export const Modals = {
    screen,
    confirm,
    unStyled,
    ModalCarrito,
}