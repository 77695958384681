import React, { useEffect } from "react"
import Layout from "components/layouts/Layout"
import useSession from "hooks/useSession"
import { useLocation, useNavigate } from "react-router-dom"
import Button from "components/displays/OldButton"
import { ButtonWrapper, Text, TextWrapper } from "./NotFoundStyles"
import { usePathsNavigator } from "util/paths"

const NotFound = (props: { msg?: string, }) => {
    const { isLogged, } = useSession()
    const { pathname, } = useLocation()
    const navigate = useNavigate()
    const paths = usePathsNavigator()

    useEffect(() => {
        if (!isLogged) {
            navigate(paths("root"), { state: pathname, })
        }
    })

    return (
        <Layout withHeaderNavigation extraClasses={{padding:16,}} >
            <TextWrapper>
                <Text>{props.msg || "Pagina no encontrada 😪"}</Text>
            </TextWrapper>
            <ButtonWrapper>
                <Button onClick={() => navigate(paths("section"))}>
                    Volver
                </Button>
            </ButtonWrapper>
        </Layout >
    )
}

export default NotFound
