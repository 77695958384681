/* eslint-disable max-lines-per-function */
import * as React from "react"
import {
    ORTC_Messages, ORTC_Direction, ORTC_Type
} from "gdu-vq-ordering-api-common/src/messages/OrchestratorToConsumer"
import {
    ORTOS_Messages, ORTOS_Direction, ORTOS_Type
} from "gdu-vq-ordering-api-common/src/messages/OrchestratorToStore"
import {
    useRecoilState, useRecoilValue, useSetRecoilState,
} from "recoil"
import { OrderProducts } from "../../api/ApiClient"
import { useRequest } from "../../hooks/useRequest"
import { OrchestratorClient } from "../../rt/OrchestratorClient"
import {
    ordersAtom, refreshStorePending, selectedSectionIdAtom, assertStoreSelector,
} from "../../state"
import { useEffect, useState, } from "react"
import produce from "immer"
import { useCurrent } from "../../hooks/useCurrent"
import LoadingModal from "components/displays/LoadingModal"
import { OrderState } from "gdu-vq-common/src/api/interfaces/shared/models/Order"
import FinishedOrderModal from "components/displays/FinishedOrderModal"
import ConfirmSectionReceptionDisable from "./ConfirmSectionReceptionDisable"
import { ConnectionState } from "gdu-vq-ordering-api-common/src/utils/RTClient"
import { getUserInfo } from "services/session"
import { getStoreBrand } from "util/paths"

type Props = {
    children?: React.ReactNode,
}

const useSocketConnect = (storeId: string) => {
    const [connectionState, setConnectionState,]
        = useState<ConnectionState>(OrchestratorClient.connectionState)

    useEffect(() => {
        OrchestratorClient.onConnectionStateChange(setConnectionState)

        OrchestratorClient.connect({
            token: getUserInfo()!.token,
            storeId: storeId,
        })

    }, [])

    return connectionState
}


// eslint-disable-next-line max-statements
export const Observer = (props: Props) => {
    const [orders, setOrders,] = useRecoilState(ordersAtom)
    const currentOrders = useCurrent(orders)
    const [finishedOrders, setFinishedOrder,] = React.useState<string[]>([])
    const store = useRecoilValue(assertStoreSelector)
    const setRefreshStore = useSetRecoilState(refreshStorePending)
    const sectionId = useRecoilValue(selectedSectionIdAtom)
    const currentSectionId = useCurrent(sectionId)
    const [sectionDisable, setSectionDisable,] = React.useState(false)
    const { brand, } = getStoreBrand()!
    useSocketConnect(store.id)

    const initialData = useRequest({
        key: "initialData",
        func: () => OrderProducts.getConsumerOrder("GET", { brand: brand!, }),
    })
    const currentInitalData = useCurrent(initialData)
    useEffect(() => {
        if (!initialData.loading && !initialData.error) {
            setOrders(initialData.data)
        }
    }, [initialData,])

    React.useEffect(() => {
        OrchestratorClient.onEvent2<ORTC_Messages>(ORTC_Direction, {
            [ORTC_Type.orderCreated]: (m) => {
                if (!currentInitalData.current.loading && currentOrders.current) {
                    if (currentOrders.current.every(o => o.id !== m.order.id)) {
                        setOrders([...currentOrders.current, m.order,])
                    } else {
                        initialData.mutate()
                    }
                }
            },
            [ORTC_Type.orderCompleted]: (m) => {
                if (currentOrders.current) {
                    setOrders(produce(os => {
                        const toUpdate = os!.find(o => o.id === m.orderId)!
                        toUpdate.orderLine = toUpdate.orderLine.map(ol => ({
                            ...ol,
                            missing: !!m.missingProductIds.includes(ol.productId),
                        }))
                        toUpdate.state = m.newState
                    }))
                    setFinishedOrder((previousValue) => [...previousValue, m.orderId,])
                } else {
                    initialData.mutate()
                }
            },
            [ORTC_Type.orderStateChange]: (m) => {
                if (!currentInitalData.current.loading && currentOrders.current) {
                    if (currentOrders.current.find(o => o.id === m.orderId)) {
                        if (m.newState === OrderState.canceled
                            || m.newState === OrderState.canceledInProgress) {
                            setOrders(currentOrders.current.filter(o => o.id !== m.orderId))
                        } else {
                            setOrders(produce(os => {
                                const toUpdate = os!.find(o => o.id === m.orderId)!
                                toUpdate.state = m.newState
                            }))
                        }
                    } else {
                        initialData.mutate()
                    }
                }
            },
        })
    }, [])

    React.useEffect(() => {
        OrchestratorClient.onEvent2<ORTOS_Messages>(ORTOS_Direction, {
            [ORTOS_Type.orderReceptionStatus]: (m) => {
                if (m.section.id === currentSectionId.current) {
                    setSectionDisable(!m.active)
                } else {
                    setRefreshStore(true)
                }
            },
        })
    }, [])

    if (initialData.error) {
        throw new Error("Error loading orders")
    }

    if (initialData.loading && orders !== undefined) {
        return <LoadingModal isOpen={true} />
    }
    if (finishedOrders.length > 0 && orders) {
        const order = orders.find(o => o.id === finishedOrders[0])!
        const handleClose = () => {
            setFinishedOrder(([_, ...previosOrder]) => previosOrder)
        }
        return (
            <>
                {props.children}
                {order && <FinishedOrderModal
                    order={order} isOpen onClose={handleClose} />}
            </>)
    }

    if (sectionDisable) {
        return <><ConfirmSectionReceptionDisable
            setSectionDisable={setSectionDisable}
            setRefresh={setRefreshStore}
        />
            {props.children}
        </>
    }

    return <>
        {props.children}
    </>
}