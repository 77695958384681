import styled from "styled-components"


type WrapperProps = {
    selected?: boolean,
}
export const Wrapper = styled.p<WrapperProps>`
    width: fit-content;
    border-radius: 8px;
    padding: 3px 6px;
    margin-top:5px;
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color:${({ selected, theme, }) => (selected ? theme.palette.primary.main : theme.palette.common.white)};
    background-color:${({ selected, theme, }) => (selected ? theme.palette.common.white : theme.palette.common.grey7)};
`
