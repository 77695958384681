import styled from "styled-components"

export const ItemDiv = styled.div`
    display: flex;
    flex-direction: line;
    flex: 1;
    width: 100%;
    margin: 15px 0 0 0;
    box-shadow: 0px 22px 26px -20px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    height: fit-content;
    border: 1px solid  ${({ theme, }) => theme.palette.common.grey7};
    font-family: Silka;
`

export const ContainerDiv = styled.div`
    padding: 13px 8px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const ProductImgDiv = styled.div`
    display: flex;
    align-items: center;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
`

export const ProductItemImgDiv = styled.div`
    width: 95px;
    height: 95px;
    border: 1px solid ${({ theme, }) => theme.palette.common.grey8};
    box-shadow: 0px 7px 6px -6px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    margin: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme, }) => theme.palette.common.white};
`

export const DescriptionPWrapper = styled.div`
    min-height: 45px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    p {
        margin: 0;
    }
`

export const DescriptionP = styled.p`
    text-align: left;
    max-height: 50px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.40px;
    line-height: 120%;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: ${({ theme, }) => theme.palette.common.black};
`

export const AddDiv = styled.div`
    flex-direction: column;
    display: flex;
    align-items: flex-start;
`

export const PriceDiv = styled.div`
    display: flex;
`

export const PriceP = styled.p`
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.02em;
    line-height: 140%;
    color:  ${({ theme, }) => theme.palette.common.grey9};
`

export const AddButton = styled.button`
    background-color:  ${({ theme, }) => theme.palette.common.white};
    border:  ${({ theme, }) => theme.palette.primary.main} 1px solid;
    border-radius: 8px;
    width: 100%;
    align-items: center;
    height: 34px;
    text-align: -webkit-center;
    margin-top:13px;
    display: flex;
    justify-content: center;
    gap: 5px;
    color: ${({ theme, }) => theme.palette.primary.main};
    justify-self: flex-end;
    align-self: flex-end;
    font-size:15px;

`