import React from "react"
import { Modals } from "styles/OldModalStyle"
import ModalComp from "components/displays/ModalComp"
import {
    ContainerConfirmModal,
    ConfirmAlert,
    ConfirmMsg,
    ButtonsContainer,
    ConfirmBackButton,
    ConfirmDeleteButton,
} from "../Style/ShoppingShowCartStyle"
import useCart from "hooks/useCart"


const ConfirmDeleteItem = (props: {
    id: string,
    name: string,
    openModal: boolean,
    closeModal: () => void,
}) => {
    const { removeCart, } = useCart()
    return (
        <ModalComp
            modalIsOpen={props.openModal}
            modalStyle={Modals.ModalCarrito}
            closeModal={props.closeModal}
        >
            <ContainerConfirmModal>
                <ConfirmAlert>
                    Eliminar producto
                </ConfirmAlert>
                <ConfirmMsg>
                    ¿Estás seguro de que deseas eliminar <b>{props.name}</b>?
                </ConfirmMsg>
                <ButtonsContainer>
                    <ConfirmDeleteButton
                        onClick={() => {
                            removeCart(props.id)
                            props.closeModal()
                        }}
                    >
                        Eliminar
                    </ConfirmDeleteButton>
                    <ConfirmBackButton
                        onClick={props.closeModal}
                    >
                        Cancelar
                    </ConfirmBackButton>
                </ButtonsContainer>
            </ContainerConfirmModal>
        </ModalComp>
    )
}

export default ConfirmDeleteItem