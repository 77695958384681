import React from "react"
import arrowRight from "assets/icons/arrow_right.svg"
import styled from "styled-components"
import ButtonTypes from "./ButtonTypes"

const StyledArrowButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme, }) => theme.palette.common.white};
    &:disabled {
        background-color: ${({ theme, }) => theme.palette.common.grey3};
    }
    &:hover {
        background-color: ${({ theme, }) => theme.palette.common.black1};
    }
    border-radius: 40%;
    background-color: ${({ theme, }) => theme.palette.common.black};
    width: 54px;
    height: 54px;
`
const ArrowButton = (props: ButtonTypes) => {
    return (
        <StyledArrowButton {...props}>
            <img src={arrowRight} alt="Icon" />
        </StyledArrowButton>
    )
}

export default ArrowButton
