import { Link } from "react-router-dom"
import styled from "styled-components"

export const StyledLink = styled(Link)`
  color: ${({ theme, }) => theme.palette.common.white};
  padding: 19px 0px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 300;
  text-decoration: none;
  border-radius: 14px;
  display: block;
  width: 100%;
  background-color: ${({ theme, }) => theme.palette.common.black};
  &:disabled {
    background-color: ${({ theme, }) => theme.palette.common.grey3};
  };
  &:hover {
    background-color: ${({ theme, }) => theme.palette.common.black1};
  };
`
export const StyledButton = styled.button<{ fontSmall: boolean, fontLight: boolean, }>`
  color: ${({ theme, }) => theme.palette.common.white};
  padding: 16px 0 16px 0;
  line-height: 140%;
  border-radius: ${({ theme, }) => theme.borderRadius};
  display: block;
  width: 100%;
  height: 60px;
  background-color: ${({ theme, }) => theme.palette.primary.main};
  font-size: ${({ fontSmall, }) => (fontSmall ? "16px" : "20px")};
  font-weight: ${({ fontLight, }) => (fontLight ? "300" : "500")};
  &:disabled {
    background-color: ${({ theme, }) => theme.palette.common.grey3};
    color: ${({ theme, }) => theme.palette.common.grey1};
    font-weight: 500;
  };
`

export const StyledButtonColor = styled.button`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110%;
  height: 70px;
  margin-left: -5%;
  padding: 0px 15px 0px 15px;
  margin-bottom: -9px;
`

export const RegularText = styled.p`
  font-size:14px;
  line-height:19.6px;
  font-weight:400;
`

export const Title = styled.h2`
  font-weight: 300;
  font-size: 32px;
  line-height: 140%;
`

export const PageTitle = styled.h2`
  font-size: 25px;
  font-weight:600;
  line-height: 131%;
  letter-spacing: -0.02em;
  color: ${({ theme, }) => theme.palette.common.black2};
  text-align: center;
  padding: 10px;
  position: fixed;
  width: 100%;
  background: rgba(246, 249, 251, 0.83);
  border-bottom: 1px solid #BDBDBD;
  backdrop-filter: blur(40px);
  z-index: 1;
  left: 0;
  margin-top: -24px;
`


export const StyledNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
`
export const ConfirmModalContent = styled.div`
  height:100%;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
`

export const LocationContainer = styled.div`
  margin-bottom: 12px;
  display: flex;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const ImgLocation = styled.img`
  display: inline;
`

export const DescriptionStore = styled.p`
  font-weight: 600;
  margin-right: 3px;
  margin-left: 6px;
  display: inline;
  ::after { 
        content: " |";
  };
`

export const DescriptionDirection = styled.p`
  margin-left: 3;
  display: inline;
`