import React from "react"
import { connectField } from "immer-form"
import InlineNotification, { NotificationType } from "components/displays/InlineNotification"
import { InputWrapper, StyledInput } from "./InputStyles"
import { theme } from "styles/theme"
export type Props = {
    type: "text",
    placeholder?: string,
    value: string,
    disabled?: boolean,
    errorMessage?: string | null,
    onChange?: (value: string) => void,
    onBlur?: () => void,
    onFocus?: (value: string) => void,
    error?: string | null | React.ReactNode,
    inputId?: string,
}


const Input = React.forwardRef((props: Props, ref: React.ForwardedRef<HTMLInputElement>,) => {
    const [displayError, setDisplayError,] = React.useState(false)
    const [isInFocus, setIsInFocus,] = React.useState(false)

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange && props.onChange(e.target.value)
        setDisplayError(false)
        if (e.target.value.length > 0) {
            setIsInFocus(true)
        }

    }

    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        props.onBlur && props.onBlur()
        if (e.target.value.length === 0) {
            setIsInFocus(false)
        }
    }

    const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        props.onFocus && props.onFocus(e.target.value)
    }
    React.useEffect(() => {
        setDisplayError(!!props.error)
    }, [props.error,])

    return <>
        <InputWrapper focus={isInFocus}>
            <label
                htmlFor={props.inputId || "input"}
                style={(displayError || props.errorMessage)
                    ? { color: theme.palette.old.error.main, }
                    : {}}>
                {props.placeholder}
            </label>
            <StyledInput
                id={props.inputId || "input"}
                type={props.type}
                value={props.value || ""}
                ref={ref}
                disabled={props.disabled}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                maxLength={15}
                onFocus={handleOnFocus}
                style={(displayError || props.errorMessage)
                    ? { border: `3px solid ${theme.palette.old.error.main}`, color: theme.palette.old.error.main, }
                    : {}
                }
            />
        </InputWrapper>
        {displayError || props.errorMessage ? (
            <InlineNotification notificationType={NotificationType.error}>
                {displayError ? props.error : props.errorMessage}
            </InlineNotification>
        ) : null}
    </>
})

export default Input

export const InputField = connectField(Input)
