import React, { ReactChild, } from "react"
import Modal from "react-modal"

import { AnimatePresence, motion } from "framer-motion"
import { Modals } from "styles/ModalStyle"
import { Close } from "./styles/ModalStyles"
import styled from "styled-components"
import CloseIcon from "assets/icons/closeIcon.png"
Modal.setAppElement("#root")

export const modalMotion = {
    initial: { opacity: 0, y: "100vh", },
    animate: { opacity: 1, y: 0, transition: { duration: 0.5, }, },
    exit: { opacity: 0, y: "100vh", transition: { duration: 0.5, }, },
}

type PropsType = {
    children: ReactChild | ReactChild[],
    closeModal?: () => void,
    modalIsOpen: boolean,
    modalStyle?: Modal.Styles | undefined,
    onAfterModalClose?: () => void,
}

const ModalComp = ({
    children,
    closeModal,
    modalIsOpen,
    modalStyle,
    onAfterModalClose,
}: PropsType) => {

    return <AnimatePresence onExitComplete={onAfterModalClose}>
        {modalIsOpen
            && <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={modalStyle}
                shouldCloseOnOverlayClick={true}
                contentElement={
                    (props, children) => <motion.div
                        style={props.style}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        variants={modalMotion}
                        onClick={e => e.stopPropagation()}
                    >
                        {children}
                    </motion.div>
                }
            >
                {
                    (modalStyle === Modals.screen || modalStyle === Modals.screenNoPadding)
                    && <Close src={CloseIcon} onClick={closeModal} />
                }
                {children}
            </Modal >
        }
    </AnimatePresence>

}

const Title = styled.p`
  
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${({ theme, }) => theme.palette.common.black};
  margin-bottom:14px;

`

const ButtonsWrapper = styled.div`
  display: flex;  
  flex-direction: column;
  justify-content:space-between;
  width:100%;
  gap: 6px;
`

const Wrapper = styled.div`
  font-family: Silka, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`

const Description = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${({ theme, }) => theme.palette.common.grey11};
  margin-bottom: 13px;

  span{
    text-decoration: underline;
    font-weight: 600;
  }
`

ModalComp.Title = Title
ModalComp.Wrapper = Wrapper
ModalComp.Description = Description
ModalComp.ButtonsWrapper = ButtonsWrapper

export default ModalComp