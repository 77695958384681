import Layout from "components/layouts/Layout"
import { motion } from "framer-motion"
import styled from "styled-components"
export const Wrapper = styled.div`
  display: flex;  
  flex-direction: column;
  justify-content:space-between;
  height:100%;
  width: 100%;
  font-family: Silka, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`

export const Title = styled.h2`
  font-weight: 600;
  font-size: 30px;
  line-height: 140%;
  margin-top:43px;
  letter-spacing: -0.8px;
  text-align: center;
  color: ${({ theme, }) => theme.palette.common.black2};
`
export const TitleWrapper = styled(motion.div)`
  position: relative;
  min-height: 180px;
`
export const SectionWrapper = styled(motion.div)`
  width:100%;
  padding-bottom: 80px;
`
export const SubTitle = styled.div`
    margin-bottom: 24px;
    margin-top: 8px;
    font-family: 'Silka';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.02em;
    color: rgba(0, 0, 0, 0.58);
    padding: 0 16px;
`

export const SectionsSelectWrapper = styled(Layout)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`