import React from "react"
import { Container } from "../styles/OnboardingPageStyles"

interface Props {
    children: React.ReactNode;
    isActive: boolean;
}

const OnboardingPage = (props: Props) => {
    return (
        <React.Fragment>
            {props.isActive && <Container
                transition={{
                    x: { type: "spring", stiffness: 300, damping: 30, },
                    opacity: { duration: 0.2, },
                }}
                initial={{ opacity: 0, x: 100, }}
                animate={{ opacity: 1, x: 0, }}
                exit={{ opacity: 0, x: -100, }}>
                {props.children}
            </Container>}
        </React.Fragment>
    )
}


export default OnboardingPage