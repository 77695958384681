import { CartItem } from "types"
import { useRecoilState } from "recoil"
import { cartAtom, } from "state"
import produce from "immer"

const useCart = () => {
    const [cart, setCart,] = useRecoilState(cartAtom)

    if (!cart) {
        throw new Error("Cart is null")
    }

    const cartLenght = cart.cartItems?.length

    const itemInCart = (itemId: string) => cart.cartItems.find(
        (ic: CartItem) => ic.productId === itemId
    )

    const addCart = (item: CartItem,) => {
        if (cart) {
		console.log("cart", cart)
		console.log("cart item", item)
            setCart(produce(c => {
                const cartItems = [...c!.cartItems, item,]
                c!.cartItems = cartItems
            }))
        } else {
            setCart(produce(c => { c!.cartItems = [item,] }))
        }
    }

    const removeCart = (itemId: string) => {
        const newCart = cart.cartItems.filter((c: CartItem) => c.productId !== itemId)
        setCart(produce(nC => { nC!.cartItems = newCart }))
    }

    const updateItem = (itemCart: CartItem) => {
        setCart(produce(c => {
            const toUpdate = c!.cartItems.find(ic => ic.productId === itemCart.productId)!
            toUpdate.quantity = itemCart.quantity
            toUpdate.comment = itemCart.comment
        }))
    }

    const clearCart = (section: string | null) => {
        if (section) {
            setCart(produce(c => {
                c!.sectionId = section!
                c!.cartItems = []
            }))
        } else {
            setCart(null)
        }
    }

    return {
        cart,
        cartLenght,
        itemInCart,
        addCart,
        removeCart,
        clearCart,
        updateItem,
    }
}

export default useCart

