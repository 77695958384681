import React from "react"
import Layout from "components/layouts/Layout"
import { GroupBy } from "gdu-vq-common/src/helpers/groupBy"
import OrderByState from "./ordersByState/OrderByState"
import { ObjectTyped } from "gdu-vq-common/src/helpers/ObjectTyped"
import { useRecoilValue, useRecoilValueLoadable } from "recoil"
import { ordersAtom, assertStoreSelector, selectedSectionIdAtom } from "state"
import { useLoadableValueHelper } from "hooks/useLoadableValueHelper"
import LoadingModal from "components/displays/LoadingModal"
import NavigationBar from "components/displays/NavigationBar"
import {
    BackButton, SectionsWrapper, Title, TagsWrapper, TitleWrapper,
} from "./ordersByState/styles/OrderStyle"
import { useNavigate } from "react-router-dom"
import { usePathsNavigator } from "util/paths"
import { ReactComponent as ArrowLeft } from "assets/icons/arrow_left.svg"

const Orders = () => {
    const order = useRecoilValue(ordersAtom)
    const navigate = useNavigate()
    const paths = usePathsNavigator()
    const sectionId = useRecoilValue(selectedSectionIdAtom)
    const responseStore = useLoadableValueHelper(useRecoilValueLoadable(assertStoreSelector))
    if (responseStore.loading || order === undefined) { return <LoadingModal /> }
    const orderList = GroupBy(order, ({ state, }) => state)

    const handleNav = (site: string) => () => navigate(site)
    const path = sectionId ? "products" : "section"
    const btnLabel = sectionId ? "Productos" : "Secciones"
    return (
        <Layout>
            <TitleWrapper>
                <Title >Pedidos</Title>
                <TagsWrapper>
                    <BackButton onClick={handleNav(paths(path))} >
                        <ArrowLeft width={13} height={11} />
                        {btnLabel}
                    </BackButton>
                </TagsWrapper>
            </TitleWrapper>
            <SectionsWrapper >
                {order.length > 0
                    ? (<>
                        {ObjectTyped.keys(orderList).sort(() => -1)
                            .map((state) => {
                                return (
                                    <OrderByState
                                        key={state}
                                        state={state}
                                        orders={orderList[state]}
                                        sections={responseStore.data!.sections}
                                    />
                                )
                            })}
                    </>) : (<p style={{
                        marginTop: 20,
                        textAlign: "center",
                    }}>No hay pedidos activos</p>)}
            </SectionsWrapper>
            <NavigationBar />
        </Layout >
    )
}

export default Orders
