export enum EVENTS {
    VIEW_WELCOME_PAGE = "viendo-bienvenida",
    NON_USER_LOYAL = "Usuario-no-fidelizado",
    LOGIN = "login",
    LOGOUT = "logout",
    CLICK_PRODUCT = "Producto-seleccionado",
    ADD_PRODUCT = "Agrega-producto",
    SEND_ORDER = "orden-enviada",
    COMPLETE_ORDER_NOTIFICATION = "viendo-notificacion-pedido-completado",
    SELECT_SECTION = "seleccionar-seccion",
    PRODUCT_SEARCH = "Buscador-de-productos",
    NO_AFILIATED_FAQ = "non-soy-cliente-que-hago-click",
    SKIP_TUTORIAL = "saltar-tutorial",
    FEEDBACK = "feedback",
}

export type LogSessionDataType = { ci: string, }