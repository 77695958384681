import { useEffect, useRef, useState } from "react"

const useScroll = () => {
    const [effectSwitch, setEffectSwitch,] = useState(false)
    const elementRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (elementRef.current) {
            elementRef.current.scrollIntoView({ behavior: "smooth", block: "center", })
        }
    }, [effectSwitch,])

    const scrollToElement = () => {
        setEffectSwitch(v => !v)
    }

    return {
        scrollToElement,
        elementRef,
    }
}

export default useScroll