/* eslint-disable max-lines-per-function */
import { useMemo } from "react"
import { assertStoreSelector, selectedSectionIdAtom, } from "state"
import { CartItem } from "types"
import { EVENTS, } from "types/TrackingEventType"
import { track } from "util/MixPanelEventsHelper"
import { useRecoilValue, useRecoilValueLoadable } from "recoil"
import { useLoadableValueHelper } from "./useLoadableValueHelper"
import { getStoreBrand } from "util/paths"

export const useTrackEvent = () => {
    const sectionId = useRecoilValue(selectedSectionIdAtom)
    const responseStore = useLoadableValueHelper(useRecoilValueLoadable(assertStoreSelector))

    return useMemo(() => {
        const { brand, } = getStoreBrand()!
        const section = responseStore.data?.sections.find(s => s.id === sectionId)!

        const clickProduct = (description: string,) => {
            track(EVENTS.CLICK_PRODUCT, {
                brand,
                sucursalDescription: responseStore.data!.description,
                sectionDescription: section.sectionType,
                description,
            })
        }

        const addProduct = (data: CartItem & { description: string, }) => {
            track(EVENTS.ADD_PRODUCT, {
                brand,
                sucursalDescription: responseStore.data!.description,
                sectionDescription: section.sectionType,
                productDescription: data.description,
                quantity: data.quantity,
                comment: data.comment,
            })
        }

        const sendOrder = (id: string, productsQty: number, totalPrice: number) => {
            track(EVENTS.SEND_ORDER, {
                brand,
                sucursalDescription: responseStore.data!.description,
                sectionDescription: section.sectionType,
                id,
                cantidadDeProductos: productsQty,
                totalPrice,
            })
        }

        const completeOrderNotification = (id: string, missingProducts: number) => {
            track(EVENTS.COMPLETE_ORDER_NOTIFICATION, {
                brand,
                sucursalDescription: responseStore.data!.description,
                sectionDescription: section.sectionType,
                id,
                missingProducts,
            })
        }

        const selectSection = (description: string,) => {
            track(EVENTS.SELECT_SECTION, {
                brand,
                sucursalDescription: responseStore.data!.description,
                sectionDescription: description,
            })
        }

        const productSearch = (keyword: string) => {
            track(EVENTS.PRODUCT_SEARCH, {
                brand,
                sucursalDescription: responseStore.data!.description,
                sectionDescription: section.sectionType,
                keyword,
            })
        }

        const feedback = (comment: string, orderId?: string, section?: string) => {
            track(EVENTS.FEEDBACK, {
                brand,
                sucursalDescription: responseStore.data!.description,
                sectionDescription: section,
                comment,
                orderId,
            })
        }

        return {
            clickProduct,
            addProduct,
            sendOrder,
            completeOrderNotification,
            selectSection,
            productSearch,
            feedback,
        }
    }, [responseStore.data,])
}