import React, { useEffect, useState } from "react"
import { defaultProductImg } from "components/utils/brandsImg/brandsImage"
import { SectionType } from "gdu-vq-common/src/api/interfaces/shared/models/Section"


export enum ImgType {
    productListItem = "productListItem",
    productDetail = "productDetail",
    productShoppingLine = "productShoppingLine",
}


const productImgStyle = {
    [ImgType.productListItem]: {
        maxHeight: "100%",
        maxWidth: "100%",
        minHeight: "42px",
        borderRadius: "15px",
    },
    [ImgType.productDetail]: {
        maxHeight: "100%",
        maxWidth: "100%",
    },
    [ImgType.productShoppingLine]: {
        alignItems: "center",

        borderRadius: "35%",
    },
}




const ProductImg = (props: {
    imageUrl: string,
    sectionType: SectionType,
    imgStyle: ImgType,
}) => {
    const [imgUrl, setImgUrl,] = useState(props.imageUrl)
    const [error, setError,] = useState(false)

    useEffect(() => {
        setImgUrl(props.imageUrl)
        setError(false)
    }, [props.imageUrl,])

    const handleChangeImgUrl = () => {
        setError(true)
    }

    const styles = productImgStyle[props.imgStyle]

    return <img
        style={{ ...styles, padding: error ? "10px" : "0", }}
        src={error ? defaultProductImg[props.sectionType] : imgUrl}
        onError={handleChangeImgUrl}
    />
}

export default ProductImg