import styled, { keyframes } from "styled-components"

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`
export const Wrapper = styled.div`
  position:relative;
  width:32px;
  height:32px;
  animation: ${rotate} 1s linear infinite;
`
export const Piece = styled.img`
  position:absolute;
  top:0;
  left:0;
  width:auto;
  height:32px;
  
`