import styled from "styled-components"

export const StyledForm = styled.form`
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap:15px;

    div{
        img{
            margin-top:0;
        }
    }
`

export const StyledLabel = styled.label`
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    width: 100%;
`

export const StyledSpan = styled.span`
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
`

export const ButtomDiv = styled.div`
    position: absolute; 
    right: 16px;
    bottom: 16px;
`

export const LogoutTitle = styled.p`
    font-size: 20px;
    line-height: 131%;
    letter-spacing: -0.02em;
    color: #333333;
    padding-bottom: 19px;
`
