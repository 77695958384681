import { DefaultTheme } from "styled-components"

export const theme: DefaultTheme = {
  borderRadius: "16px",
  palette: {
    common: {
      black: "#333333",
      black1: "#1f1f1f",
      black2: "#000000",
      white: "#ffffff",
      offWhite: "#fafafa",
      offWhite1: "#ffffffcc",
      offWhite2: "#f2f2f2",
      grey: "#e6e6e6",
      grey1: "#B3B3B3",
      grey2: "#595959",
      grey3: "#ededed",
      grey4: "#999999",
      grey5: "#737373",
      grey6: "#777777",
      grey7: "#BDBDBD",
      grey8: "#E0E0E0",
      grey9: "#828282",
      grey10: "#c6c6c6",
      grey11: "#4F4F4F",
      grey12: "#E5E8EB",
      grey13: "#5D595A",
      grey14: "#7C7C80",
      green: "#006937",
      green1: "#0069371a",
      green2: "#d8e4de",
      green3: "#004223",
      red: "#ff6c6c",
      red1: "#4F0303",
      red2: "#EB4B4B",
    },
    primary: {
      main: "#006937",
      contrastText: "#fafafa",
    },
    secondary: {
      main: "rgba(87, 194, 117, 0.73)",
      contrastText: "#fafafa",
    },
    error: {
      main: "#BD111B",
      contrastText: "#f9ecec",
    },
    old: {
      primary: {
        main: "#D6131E",
        contrastText: "#fafafa",
      },
      secondary: {
        main: "#D6131E",
        contrastText: "#fafafa",
      },
      error: {
        main: "#BD111B",
        contrastText: "#f9ecec",
      },
      info: {
        main: "#EB854B",
        contrastText: "#fdf3ee",
      },
    },
  },
}