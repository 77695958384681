/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from "react"
import AddButton from "components/utils/AddButton"
import RemoveButton from "components/utils/RemoveButton"
import useCart from "hooks/useCart"
import { useNavigate, useParams } from "react-router-dom"
import Layout from "components/layouts/Layout"
import { StyledButton } from "components/displays/Elements"
import {
  InfoP,
  KeypadQtyDiv,
  QtyP,
  UnitP,
  ProductDetailDiv,
  CustomizedDiv,
  PriceWrapper,
  Subtotal,
  EstimatedWarning,
  Amount,
} from "./ProductListStyle/ProductDetailStyle"
import {
  calculatePrice,
  incrementToReadable,
  productReadablePrice
} from "gdu-vq-common/src/api/interfaces/shared/helpers/ProductUtil"
import ConfirmDeleteItem from "pages/shoppingcart/showCart/ConfirmDeleteItem"
import ProductDetailData from "./ProductDetailData"
import { CartItem } from "types"
import { useRecoilValueLoadable } from "recoil"
import { productsSelector } from "state"
import { useLoadableValueHelper } from "hooks/useLoadableValueHelper"
import LoadingModal from "components/displays/LoadingModal"
import { usePathsNavigator } from "util/paths"
import useScroll from "hooks/useScroll"
import { useTrackEvent, } from "hooks/useTrackEvent"
import { InputTextStyled } from "components/displays/styles/InputTextStyled"

const getInitialData = (
  productInCart: CartItem | undefined,
  readableIncrements: number
) => {
  const initialQty
    = productInCart && productInCart.quantity > 0
      ? productInCart.quantity
      : readableIncrements

  const initialComment = productInCart ? productInCart.comment! : ""

  return { initialQty, initialComment, }
}

// eslint-disable-next-line max-statements
const ProductDetail = (props: { backPath: "products" | "shoppingCart", }) => {
  const productId = useParams<"product_id">().product_id
  const products = useLoadableValueHelper(
    useRecoilValueLoadable(productsSelector)
  )
  const { addProduct, } = useTrackEvent()
  const { addCart, itemInCart, updateItem, } = useCart()
  const product = products.data?.productsById[productId!]!
  const productInCart = itemInCart(productId!)
  const { scrollToElement, elementRef, } = useScroll()

  const navigate = useNavigate()
  const paths = usePathsNavigator()
  const [confirmModal, setConfirmModal,] = useState<boolean>(false)
  const [quantity, setQuantity,] = useState<number>(0)
  const [comment, setComment,] = useState<string>("")
  const {
    increment: readableIncrements,
    unit: readableUnit,
  } = incrementToReadable(product.increments, product.unit, quantity, true)

  useEffect(() => {
    const { initialQty, initialComment, } = getInitialData(
      productInCart,
      readableIncrements
    )
    setQuantity(initialQty)
    setComment(initialComment)
  }, [productInCart, product,])

  if (products.loading) {
    return <LoadingModal />
  }

  const handleOnCheck = () => {
    if (productInCart && productInCart.quantity) {
      updateItem({
        productId: product.id,
        quantity: quantity,
        comment: comment,
      })
    } else {
      const addCartData = { productId: product.id, quantity: quantity, comment: comment, }
      addProduct({ ...addCartData, description: product.description, })
      addCart(addCartData)
    }
    navigate(paths(props.backPath))
  }

  const renderButtons = () => {
    if (productInCart) {
      return (
        <StyledButton
          onClick={handleOnCheck}
          fontSmall={false}
          fontLight={true}
        >
          Guardar
        </StyledButton>
      )
    }
    return (
      <StyledButton onClick={handleOnCheck} fontSmall={false} fontLight={true}>
        Agregar al carrito
      </StyledButton>
    )
  }

  const renderProductQty = () => {
    return (
      <PriceWrapper>
        <Subtotal>
          {productReadablePrice(
            calculatePrice(
              product.price,
              product.increments,
              quantity,
              product.unit
            )
          )}
        </Subtotal>
        <EstimatedWarning>Monto estimado</EstimatedWarning>
        <KeypadQtyDiv>
          <RemoveButton
            aleternativeImg={true}
            onClick={() =>
            (quantity <= readableIncrements
              ? productInCart && setConfirmModal(true)
              : setQuantity(quantity - readableIncrements))
            }
          />
          <Amount>
            <QtyP>{quantity}</QtyP>&nbsp;
            <UnitP>{readableUnit}</UnitP>
          </Amount>
          <AddButton
            onClick={() => {
              setQuantity(quantity + readableIncrements)
            }}
          />
        </KeypadQtyDiv>
      </PriceWrapper>
    )
  }

  const renderProductComment = () => {
    return (
      <div>
        <InfoP>Comentarios</InfoP>
        <InputTextStyled
          onFocus={() => {
            scrollToElement()
          }}
          value={comment}
          placeholder="Comentarios"
          onChange={e => setComment(e.currentTarget.value)}
        />
      </div>
    )
  }

  return (
    <Layout>
      <ProductDetailDiv>
        <ProductDetailData product={product} quantity={quantity} />

        {renderProductQty()}
        {renderProductComment()}
        <CustomizedDiv ref={elementRef}>{renderButtons()}</CustomizedDiv>

        {confirmModal && (
          <ConfirmDeleteItem
            id={product.id}
            name={product.description}
            openModal={confirmModal}
            closeModal={() => setConfirmModal(false)}
          />
        )}
      </ProductDetailDiv>
    </Layout>
  )
}

export default ProductDetail
