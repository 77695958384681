import React from "react"
import useSession from "hooks/useSession"
import { getUserInfo } from "services/session"
import { DefaultTheme, withTheme } from "styled-components"
import { trackEvent } from "util/MixPanelEventsHelper"

import { Modals } from "styles/ModalStyle"
import ModalComp from "components/displays/Modal"
import {
    RedButtonText,
    RedButtonWrapper,
    FirstHeader, FirstText, FirstWrapper, Hr, ThreeInfoWrapper,
} from "styles/ThreeInfoDisplayStyles"
import { LogoutTitle } from "./styles/LoginStyles"
import { useTrackEvent } from "hooks/useTrackEvent"
import Feedback from "components/displays/Feedback"

type PropsType = {
    cancel: () => void,
    logoutRedirect: string,
    theme: DefaultTheme,
}

const LogOut = ({
    cancel,
    logoutRedirect,
    theme,
}: PropsType) => {
    const { finishSession, } = useSession()
    const { feedback, } = useTrackEvent()
    const handleCloseSession = () => {
        finishSession()
        trackEvent.logout()
        window.location.href = logoutRedirect
    }

    const ciFormatted = () => {
        const ci = getUserInfo()!.userCI
        const ciNumber = ci.substring(0, ci.length - 1)
        const guionNumber = ci.substring(ci.length - 1, ci.length)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const number = Math.trunc(ciNumber as any as number).toLocaleString()
            .replaceAll(",", ".")
        return `${number}-${guionNumber}`
    }

    return <ModalComp
        modalIsOpen={true}
        modalStyle={Modals.screen}
        closeModal={cancel}
    >
        <LogoutTitle>{getUserInfo()?.userName}</LogoutTitle>
        <Hr />
        <ThreeInfoWrapper>
            <FirstWrapper>
                <FirstText>Documento de identidad:</FirstText>
                <FirstHeader>{ciFormatted()}</FirstHeader>
                <RedButtonWrapper>
                    <RedButtonText onClick={(e) => { e.stopPropagation(); handleCloseSession() }}>
                        Cerrar sesión
                    </RedButtonText>
                </RedButtonWrapper>
            </FirstWrapper>
        </ThreeInfoWrapper>
        <Hr />
        <Feedback
            sendComment={(comment: string) => feedback(comment)}
        />
    </ModalComp>
}

export default withTheme(LogOut)