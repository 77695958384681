import React from "react"
import {
    productReadablePrice,
    productUnitToReadableSingularText,
} from "gdu-vq-common/src/api/interfaces/shared/helpers/ProductUtil"
import {
    StoreSectionProducts
} from "gdu-vq-common/src/api/interfaces/consumer/models/ConsumerStore"
import {
    ProductImgDiv,
    ProductInfoDiv,
    ProductDetailP,
    DescriptionP,
    ProductHeader,
    ProductUnit,
} from "./ProductListStyle/ProductDetailDataStyle"
import { useRecoilValueLoadable, } from "recoil"
import { sectionSelectAtom } from "state"
import { useLoadableValueHelper } from "hooks/useLoadableValueHelper"
import LoadingModal from "components/displays/LoadingModal"
import ProductImg, { ImgType } from "util/ProductImg"


const ProductDetailData = (props: {
    product: StoreSectionProducts,
    quantity: number,
}) => {
    const { imageUrl, productBrand, description, price, unit, } = props.product
    const section = useLoadableValueHelper(useRecoilValueLoadable(sectionSelectAtom))

    if (section.loading) { return <LoadingModal /> }

    return <>
        <ProductHeader>
            <ProductImgDiv>
                <ProductImg
                    imgStyle={ImgType.productDetail}
                    imageUrl={imageUrl}
                    sectionType={section.data!.sectionType}
                />
            </ProductImgDiv>
            <ProductInfoDiv>
                <ProductDetailP>
                    {productBrand}{" - "}
                    {productReadablePrice(price)}{" "}
                    <ProductUnit>por {productUnitToReadableSingularText(unit)}</ProductUnit>
                </ProductDetailP>

                <DescriptionP>{description}</DescriptionP>
            </ProductInfoDiv>
        </ProductHeader>
    </>
}

export default ProductDetailData