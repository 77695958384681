import React from "react"
import { Section, SectionWrapper, Title } from "./ModalContentStyles"

const ModalContent = () => {

    const contents = [
        "Presentate con tu CI en Atención al Cliente en cualquiera de nuestros locales",
    ]

    return <div>
        <Title>
            ¿Cómo ser cliente Más o Hipercard?
        </Title>
        <SectionWrapper>
            {contents.map((val, index) => (
                <Section key={index}>
                    {val}
                </Section>
            ))}
        </SectionWrapper>
    </div>
}

export default ModalContent