import React from "react"
import { ReactComponent as UserIcon } from "assets/icons/userIcon.svg"
import { ReactComponent as SectionIcon } from "assets/icons/sectionIcon.svg"
import { ReactComponent as OrderIcon } from "assets/icons/orderIcon.svg"
import { ReactComponent as BagIcon } from "assets/icons/bagIcon.svg"
import { Badge, Container, Label } from "./styles/NavigationItemStyles"
import { DefaultTheme, withTheme } from "styled-components"


export enum ICONS {
    SECTION = "SECTION",
    ORDER = "ORDER",
    BAG = "BAG",
    USER = "USER"
}


type PropsType = {
    isActive: boolean,
    icon: ICONS,
    label: string,
    badge?: number,
    onClick: () => void,
    theme: DefaultTheme,
    disable: boolean,
}

const NavigationItem = ({
    isActive, icon, label, badge, onClick, theme, disable,
}: PropsType) => {
    const rednerBadge = () => {
        return badge ? <Badge>
            <p>{badge}</p>
        </Badge> : <></>
    }
    const iconColor = isActive
        ? theme.palette.primary.main
        : theme.palette.common.grey6

    const getIcon = (icon: ICONS) => {
        switch (icon) {
            case ICONS.SECTION:
                return <SectionIcon fill={iconColor} />
            case ICONS.ORDER:
                return <OrderIcon fill={iconColor} />
            case ICONS.BAG:
                return < BagIcon fill={iconColor} />
            case ICONS.USER:
                return < UserIcon fill={iconColor} />
            default:
                return <></>
        }
    }

    return (
        <Container disable={disable} onClick={onClick}>
            {rednerBadge()}
            {getIcon(icon)}
            <Label style={{ color: iconColor, }}>
                {label}
            </Label>
        </Container>
    )
}

export default withTheme(NavigationItem)