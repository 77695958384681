/* eslint-disable max-lines-per-function */
import React, { useMemo, } from "react"
import Fuse from "fuse.js"
import {
    StoreSectionProducts,
} from "gdu-vq-common/src/api/interfaces/consumer/models/ConsumerStore"
import ProductItem from "./ProductItem"
import NavigationBar from "components/displays/NavigationBar"
import { useRecoilState, useRecoilValue, } from "recoil"
import { productsSelector, sectionSelectAtom, productFilterAtom } from "state"
import Layout from "components/layouts/Layout"
import { List, AutoSizer, CellMeasurerCache, CellMeasurer } from "react-virtualized"
import {
    ProductsDiv,
    FilterMsgP,
    HideScrollBar,
    Line,
} from "./ProductListStyle/ProductListStyle"
import { MeasuredCellParent } from "react-virtualized/dist/es/CellMeasurer"
import { useWindowSize } from "hooks/useWindowsSize"
import ProductsHeader from "components/displays/ProductsHeader"
import { useNavigate } from "react-router-dom"
import { usePathsNavigator } from "util/paths"
import { useTrackEvent } from "hooks/useTrackEvent"


const productKeys: (keyof StoreSectionProducts)[] = ["description", "productBrand", "keywords",]

const options = {
    includeScore: true,
    keys: productKeys,
    shouldSort: true,
    findAllMatches: true,
    minMatchCharLength: 2,
    threshold: 0.2,
    useExtendedSearch: true,
}

const cache = new CellMeasurerCache()

const ProductList = (props: {
    onProductDetail: (product: StoreSectionProducts) => void,
}) => {
    const navigate = useNavigate()
    const paths = usePathsNavigator()
    const { productSearch, } = useTrackEvent()
    const loadedSection = useRecoilValue(sectionSelectAtom)!
    const loadedProducts = useRecoilValue(productsSelector)
    const [productFilter, setProductFilter,] = useRecoilState(productFilterAtom)
    const [displayLine, setDisplayLine,] = React.useState(false)
    const size = useWindowSize()
    const filter = useMemo(() => {
        return new Fuse(loadedProducts.products, options)
    }, [loadedProducts,])

    const handleNav = (site: string) => () => navigate(site)

    const productList = useMemo(() => {
        if (productFilter) {
            const productFilterSearchForIncludedWords = productFilter.replace(/(\w+)/gi, "'$1")
            const result = filter!.search(productFilterSearchForIncludedWords)
            return result.map(r => r.item)
        } else {
            return loadedProducts.products
        }
    }, [loadedProducts, productFilter,])


    const handleFilterProductList = (product: string) => {
        setProductFilter(product)
        productSearch(product)
    }


    const renderHeader = () => {
        return (
            <ProductsHeader
                handleFilterProductList={handleFilterProductList}
                productFilter={productFilter}
                waitTime={loadedSection.waitTime}
                sectionName={loadedSection.sectionType}
                changeSection={handleNav(paths("section"))}
            />
        )
    }

    const renderRow = (
        { key,
            index,
            style, parent, }: {
                key: string,
                index: number,
                style: React.CSSProperties,
                parent: MeasuredCellParent,
            }) => {



        const product = productList![index]

        return (
            <CellMeasurer
                cache={cache}
                columnIndex={0}
                key={key}
                rowIndex={index}
                parent={parent}>

                {() => {
                    return <ProductsDiv style={style}>
                        {product
                            && <ProductItem
                                key={product.id}
                                productItem={product}
                                onClick={() => props.onProductDetail(product)}
                            />}

                    </ProductsDiv>
                }}
            </CellMeasurer>)

    }

    const sized = ({ width, height, }: { width: number, height: number, }) => {
        return <List
            searchQuery={productFilter}
            height={(size.height)}
            deferredMeasurementCache={cache}
            width={width}
            overscanRowCount={0}
            rowCount={productList!.length}
            rowHeight={cache.rowHeight}
            rowRenderer={renderRow}
            className="List"
            onScroll={event => {
                setDisplayLine(event.scrollTop > 0)
            }}
            style={{
                paddingBottom: "94px",
            }}
        />
    }

    const renderContent = () => {
        if (productFilter && productFilter.length <= 1) {
            return <FilterMsgP>
                La palabra clave debe contener un minimo de 2 letras.
            </FilterMsgP>
        } else if (!productList || productList.length === 0) {
            return <FilterMsgP>
                No hay resultados para tu búsqueda. ¡Probá con otra palabra clave!
            </FilterMsgP>
        } else {
            return <HideScrollBar>
                <AutoSizer>{sized}</AutoSizer>
            </HideScrollBar>
        }
    }

    return <Layout withHeaderNavigation>
        {renderHeader()}

        <Line visible={displayLine} />
        {renderContent()}
        <NavigationBar />
    </Layout >
}

export default ProductList